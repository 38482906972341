import React, {useEffect} from 'react';
import '../../../common/css/datagrid/datatables/datatables.bundle.css';
import {useParams, useHistory} from 'react-router-dom';

const $ = require('jquery');
require("datatables.net-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis.js");
require("datatables.net-buttons/js/buttons.flash.js");
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");
require("datatables.net-colreorder-bs4");
require("datatables.net-responsive-bs4");
require("datatables.net-select-bs4");

const CommercialsTable = () => {

  const params = useParams();
  const history = useHistory();

  const fetchData = () => {
    if(!params.campaign_id) return;
    fetch("https://fdmvp.azrdev.psadirector.net/test/get-commercials/" + params.campaign_id)
    .then(res => res.json())
    .then(
      result => {
        console.log(result.data);
        if($('#dt-commercials').hasClass('dataTable')) {
          $('#dt-commercials').DataTable().destroy();
        }
        $('#dt-commercials').DataTable({
          data: result.data,
          columns: result.columns
        });
      }
    )
  }

  useEffect(() => {
    fetchData();
  })

  return (
    <table className="dt-commercials table table-bordered table-hover table-striped w-100" id="dt-commercials" ></table> 
  )
}

CommercialsTable.propTypes = {};
CommercialsTable.defaultProps = {};

export default CommercialsTable;
