import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './Nav.module.scss';
import {Link} from 'react-router-dom';
import Global from '../../../Global';

const Nav = () => (
  <nav id="js-primary-nav" className="primary-nav" role="navigation">
    <div className="nav-filter">
        <div className="position-relative">
            <input type="text" id="nav_filter_input" placeholder="Filter menu" className="form-control" tabindex="0" />
            <a href="#" onClick="return false" className="btn-primary btn-search-close js-waves-off" data-action="toggle" data-class="list-filter-active" data-target=".page-sidebar">
                <i className="fal fa-chevron-up"></i>
            </a>
        </div>
      </div>
      <ul id="js-nav-menu" className="nav-menu">
        {/* include the dashboar condition here */}
            <li>
                <Link to="/clients">
                    <i className="fal fa-address-book"></i>
                    <span className="nav-link-text" data-i18n="nav.category">Clients</span>
                </Link>                
            </li>
            <li>
                <Link to="/campaigns/100000">
                    <i className="fal fa-ballot"></i>
                    <span className="nav-link-text" data-i18n="nav.category">Campaigns</span>
                </Link>       
            </li>
            <li>
                <Link to="/commercials/100000/100001">
                    <i className="fal fa-ad"></i>
                    <span className="nav-link-text" data-i18n="nav.category">Commercials</span>
                </Link>       
            </li>
            <li>
                <Link to="/media">
                    <i className="fal fa-tv-retro"></i>
                    <span className="nav-link-text" data-i18n="nav.category">Media</span>
                </Link>       
            </li>
            <li>
                <Link to="/markets">
                    <i className="fal fa-map-marker-alt"></i>
                    <span className="nav-link-text" data-i18n="nav.category">Markets</span>
                </Link>       
            </li>
            <li>
                <Link to="/results">
                    <i className="fal fa-ballot"></i>
                    <span className="nav-link-text" data-i18n="nav.category">Results</span>
                </Link>       
            </li>
            {/* <li>
                <Link to="/new-campaign/100004">
                    <i className="fal fa-user-cog"></i>
                    <span className="nav-link-text" data-i18n="nav.category">Market Rates</span>
                </Link>       
            </li> */}
            <li>
                <Link to="/import/tv">
                    <i className="fal fa-tv"></i>
                    <span className="nav-link-text" data-i18n="nav.category">TV / Cable Import</span>
                </Link>         
            </li>
            <li>
                <Link to="/import/radio">
                    <i className="fal fa-radio"></i>
                    <span className="nav-link-text" data-i18n="nav.category">Radio Import</span>
                </Link>
            </li>
            <li>
                <Link to="/test/results">
                    <i className="fal fa-ballot"></i>
                    <span className="nav-link-text" data-i18n="nav.category">Test Import</span>
                </Link>
            </li>
            <li>
                <Link to="/settings">
                    <i className="fal fa-ballot"></i>
                    <span className="nav-link-text" data-i18n="nav.category">Settings</span>
                </Link>
            </li>
            {/* <li>
                <a href="#" title="Users">
                    <i className="fal fa-analytics"></i>
                    <span className="nav-link-text" data-i18n="nav.blankpage">Users</span>
                </a>
            </li>  */}
            <li>
                <a href={Global.dashboardURL} title="Dashboard">
                    <i className="fal fa-analytics"></i>
                    <span className="nav-link-text" data-i18n="nav.blankpage">Dashboard</span> 
                </a>
            </li> 
      </ul>

  </nav>
);

Nav.propTypes = {};
Nav.defaultProps = {};

export default Nav;
