import React from 'react';
import {useHistory, withRouter} from 'react-router-dom'
import {Form, Button, Row, Col} from 'react-bootstrap';

const SubmitButton = (props) => {

	const history = useHistory()
	const handleCancel = () => {
		let path = history.location.pathname
		let splitPath = ['']
		if(path.indexOf('add') != -1) {
			splitPath = path.split('/add')
		} else if(path.indexOf('edit') != -1) {
			splitPath = path.split('/edit')
		} else {
			splitPath = path.split('/')
		}

		//history.goBack()
		if(splitPath[0] != '') history.push(splitPath[0])
		else history.goBack()
	}

	return (
		<Row style={{width: "180px"}}>
			<Col>                
				<Form.Group>
					<Button variant="primary" type="submit">Submit</Button>
				</Form.Group>
			</Col>     
			<Col>                
				<Form.Group>
					<Button variant="danger" onClick={handleCancel}>Cancel</Button>
				</Form.Group>
			</Col>   
		</Row>
	);
};

const SubmitButtonA = withRouter(SubmitButton)

export default SubmitButtonA;
