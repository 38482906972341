/* eslint-disable */
import logo from './logo.svg';
import './App.css';
import ClientAdd from './components/Modules/Clients/ClientAdd';
import ClientView from './components/Modules/Clients/Clients';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import CampaignFormPage from './pages/CampaignFormPage';
import CampaignPage from './pages/CampaignPage';
import CommercialPage from './pages/CommercialPage';
import MediasPage from './pages/MediasPage';
import MarketsPage from './pages/MarketsPage';
import TVImport from './pages/TVImport';
import RadioImport from './pages/RadioImport';
import ClientsPage from './pages/ClientsPage';
import ResultsPage from './pages/ResultsPage';
import SettingsPage from './pages/Settings';
import RedirectHome from './RedirectHome';
import TestResults from './pages/TestResults';
import AuthProvider from './Auth/AuthProvider';
import RequireAuth from './Auth/RequireAuth';
import Global from './Global';

function App() {
  return (
    <AuthProvider>
      <Router basename={Global.basename}>
        <Switch>
          <Route exact path="/clients" component={() => <RequireAuth><ClientsPage /></RequireAuth>} />
          <Route exact path="/clients/new" component={() => <RequireAuth><ClientAdd /></RequireAuth>} />
          <Route exact path="/clients/edit/:client_id" component={() => <RequireAuth><ClientAdd /></RequireAuth>} />
          <Route exact path="/client-view/:client_id" component={() => <RequireAuth><ClientView /></RequireAuth>} />
          <Route exact path="/client-view" component={() => <RequireAuth><ClientView /></RequireAuth>} />
          <Route exact path="/clients" component={() => <RequireAuth><ClientView /></RequireAuth>} />
          <Route exact path="/campaigns/:client_id" component={() => <RequireAuth><CampaignPage /></RequireAuth>} />
          <Route exact path="/campaigns/:client_id/edit/:campaign_id" component={() => <RequireAuth><CampaignFormPage /></RequireAuth>} />
          <Route exact path="/new-campaign/:client_id" component={() => <RequireAuth><CampaignFormPage /></RequireAuth>} />
          <Route exact path="/commercials/:client_id" component={() => <RequireAuth><CommercialPage /></RequireAuth>} />
          <Route exact path="/commercials/:client_id/:campaign_id" component={() => <RequireAuth><CommercialPage /></RequireAuth>} />
          <Route exact path="/commercials/:client_id/:campaign_id/:event" component={() => <RequireAuth><CommercialPage /></RequireAuth>} />
          <Route exact path="/commercials/:client_id/:campaign_id/:event/:commercial_id" component={() => <RequireAuth><CommercialPage /></RequireAuth>} />
          <Route exact path="/media" component={() => <RequireAuth><MediasPage /></RequireAuth>} />
          <Route exact path="/media/:event" component={() => <RequireAuth><MediasPage /></RequireAuth>} />
          <Route exact path="/media/:event/:media_id" component={() => <RequireAuth><MediasPage /></RequireAuth>} />
          <Route exact path="/markets" component={() => <RequireAuth><MarketsPage /></RequireAuth>} />
          <Route exact path="/markets/:event" component={() => <RequireAuth><MarketsPage /></RequireAuth>} />
          <Route exact path="/markets/:event/:market_id" component={() => <RequireAuth><MarketsPage /></RequireAuth>} />
          <Route exact path="/results" component={() => <RequireAuth><ResultsPage /></RequireAuth>} />          
          <Route exact path="/results/:event" component={() => <RequireAuth><ResultsPage /></RequireAuth>} />
          <Route exact path="/test/results" component={() => <RequireAuth><TestResults /></RequireAuth>} />
          <Route exact path="/results/:event/:result_id" component={() => <RequireAuth><ResultsPage /></RequireAuth>} />
          <Route exact path="/import/tv" component={() => <RequireAuth><TVImport /></RequireAuth>} />
          <Route exact path="/import/radio" component={() => <RequireAuth><RadioImport /></RequireAuth>} /> 
          <Route exact path="/settings" component={() => <RequireAuth><SettingsPage /></RequireAuth>} />                 
          <Route exact path="/" component={() => <RequireAuth><RedirectHome /></RequireAuth>} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
