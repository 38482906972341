import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {Form, Button, Row, Col} from 'react-bootstrap';
import SubmitButton from '../../UI/Form/SubmitButton';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { MuiPickersUtilsProvider, TimePicker, DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import './styles.scss'
import $ from 'jquery';
import SelectBox from '../../UI/Form/SelectBox';
import Global from '../../../Global';
import axios from '../../Helpers/axiosDefault';
import AsyncSelect from 'react-select/async';
import Validate from '../../UI/Form/Validate/Validate';
import NumberHelper from '../../Helpers/NumberHelper';
// import TimePicker from 'rc-time-picker';
// import 'rc-time-picker/assets/index.css';
//import DatePicker from '../../UI/DatePicker/DatePicker';



/*
client
campaign
advertisement
media / Stations / 
program

pub_value
pub_placements / Insersions / Airings / Units
pub_impr_circ / Impressions / Circulations / Exposures

pub_date
pub_time
pub_type / daypart / 

*/

const ResultForm = () => {
	const [selectedDate, handleDateChange] = useState(null);
	const [selectedTime, handleTimeChange] = useState(null);
	const params = useParams()
	const history = useHistory()
	const showFillDefaultValueButton = false

	let mediaSelectField = useRef()

	let [client, setClient] = useState('')
	let [campaign, setCampaign] = useState('')
	let [commercial, setCommercial] = useState('')
	let [isImport, setIsImport] = useState('')

	let [mediaType, setMediaType] = useState('')
	let [disabled, setDisabled] = useState(false)
	let [mediaDefaultValue, setMediaDefaultValue] = useState([])
	let [programDefaultValue, setProgramDefaultValue] = useState([])
	let rateImprs = {rate:0, impression:0}

	const onChangeHandler = (e) => {
		if(e.target.name == 'client_id') {
			setClient(e.target.value)
			setCampaign('')
			document.querySelector('#campaign_id').value = ''
		}
		if(e.target.name == 'campaign_id') {			
			setCampaign(e.target.value)
			document.querySelector('#advertisement_id').value = ''
		}
		if(e.target.name == 'media_type') setMediaType(e.target.value)
	}

	let fields = [
		//['media', 'program'],
		['pub_placements', 'pub_impr_circ', 'pub_value'],
		//['pub_date', 'pub_time', 'pub_type']
	]

	let label = {
					'Television':{
						'program': 'Program',
						'media': 'Station',
						'pub_placements': 'Airings',
						'pub_impr_circ': 'Impressions',
						'pub_type': 'Daypart',
						'pub_value': 'Value',
						'pub_time': 'Air Time',
						'pub_date': 'Air Date',
						'is_import': ''
					},
					'NationalCable':{
						'program': 'Program',
						'media': 'Station',
						'pub_placements': 'Airings',
						'pub_impr_circ': 'Impressions',
						'pub_type': 'Daypart',
						'pub_value': 'Value',
						'pub_time': 'Air Time',
						'pub_date': 'Air Date',
						'is_import': ''
					},
					'LocalCable':{
						'program': 'Program',
						'media': 'Station',
						'pub_placements': 'Airings',
						'pub_impr_circ': 'Impressions',
						'pub_type': 'Daypart',
						'pub_value': 'Value',
						'pub_time': 'Air Time',
						'pub_date': 'Air Date',
						'is_import': ''
					},
					'Radio': {
						'program': '',
						'media': 'Radio Station',
						'pub_placements': 'Airings',
						'pub_impr_circ': 'Impressions',
						'pub_type': '',
						'pub_value': 'Value',
						'pub_time': 'Air Time',
						'pub_date': 'Air Date',
						'is_import': ''
					},
					'Newspaper': {
						'program': '',
						'media': 'Newspaper Media',
						'pub_placements': 'Insertions',
						'pub_impr_circ': 'Circulations',
						'pub_type': '',
						'pub_value': 'Value',
						'pub_time': '',
						'pub_date': 'Date',
						'is_import': ''
					},
					'Magazine': {
						'program': '',
						'media': 'Magazine Media',
						'pub_placements': 'Insertions',
						'pub_impr_circ': 'Circulations',
						'pub_type': '',
						'pub_value': 'Value',
						'pub_time': '',
						'pub_date': 'Date',
						'is_import': ''
					},
					'Ooh': {
						'program': '',
						'media': 'OOH Media',
						'pub_placements': 'Quantity',
						'pub_impr_circ': 'Exposures',
						'pub_type': '',
						'pub_value': 'Value',
						'pub_time': '',
						'pub_date': 'Date',
						'is_import': ''
					}
				};
	
	const SubmitAction = (e) => {
		e.preventDefault()
		let el;
		if(mediaType == '') {
			el = ['client_id', 'campaign_id', 'media_type', 'advertisement_id']
			if(!Validate.checkArray(el)) return false
		} else {
			el = ['media_id', 'client_id', 'campaign_id', 'media_type', 'advertisement_id', 'pub_placements', 'pub_impr_circ', 'pub_value']
		}
		
		el.push('pub_date')
		let timeValidation = ['Television', 'Radio', 'LocalCable', 'NationalCable']
		if(timeValidation.includes(mediaType)) {
			el.push('pub_time')
			if(mediaType != 'Radio')
				el.push('pub_type')
		}
		if(!Validate.checkArray(el)) return false

		const formData = new FormData(e.target);
		let url = Global.apiHost + '/api/results/save'
		axios.post(url, formData)
			.then(res => {
				history.push('/results')
			})
	}

	useEffect(() => {
		onClose();
	})

	useEffect(() => {
		if(params.result_id) { 
			let url = Global.apiHost + '/api/results/get/' + params.result_id
			axios.get(url)
				.then(res => {
					setIsImport(res.data.is_import)
					setClient(res.data.client_id)
					setCampaign(res.data.campaign_id)
					setCommercial(res.data.advertisement_id)
					setMediaType(res.data.media_type)					
					for(let x in label[res.data.media_type]) {
						if(label[res.data.media_type][x] == '') continue
						
						if(x == 'pub_date') {
							handleDateChange(res.data[x])
						} else if(x == 'pub_time') {
							handleTimeChange(res.data[x])
						} else if(x == 'media') {
							setMediaDefaultValue([{'value': res.data.media_id, 'label':res.data.media}])							
						} else if(x == 'program') {
							setProgramDefaultValue([{'value': res.data.program_id, 'label':res.data.program}])		
						} else {		
							document.querySelector('#' + x).value = res.data[x]
						}
																
					}	
					setDisabled(true)				
				})
		}
	}, [params.result_id])
 
	const onChangeMediaType = (e) => {
		// if(e.target.value.indexOf('Cable') != -1) setMediaType('Television')
		// else 
		// if(document.querySelector('#media-select-wrap'))
		// 	document.querySelector('#media-select-wrap').innerHTML = '<test>';

		setMediaType(e.target.value)
		// var t = setTimeout(() => {
		// 	setMediaType(e.target.value)
		// }, 1000)
	}

	const getProgram = () => {
		if(mediaType == '') return 		
	}

	const mediaLoadOptions = (input, callBack) => {
		if(input == undefined) input = ''
		let url = Global.apiHost + '/api/form/media?k=' + input + '&mt=' + mediaType;
		axios.get(url)
			.then(res => {
				callBack(res.data);
			})
	}

	const programLoadOptions = (input, callBack) => {
		if(input == undefined) input = ''
		let url = Global.apiHost + '/api/form/programs?k=' + input + '&mt=' + mediaType;
		axios.get(url)
			.then(res => {
				callBack(res.data);
			})
	}

	const onChangeMediaHandle = (e) => {
		console.log(e)
	}

	const fillDefaultValues = () => {
		let el = ['media_id', 'client_id', 'campaign_id', 'media_type', 'advertisement_id']
		if(mediaType == 'Television') {
			el.push('pub_time')
			el.push('pub_date')
		}
		if(!Validate.checkArray(el)) return

		const formData = new FormData(document.querySelector('#result-form'));
		let url = Global.apiHost + '/api/form/results/rates'
		axios.post(url, formData)
			.then(res => {
				rateImprs = res.data
				let airings = document.querySelector('[name=pub_placements]')

				if(isNaN(parseInt(airings.value))) {
					airings.value = 1
				}
				calculateValue()
			})
	}

	const getMedia = () => {
		if(mediaType == '') return 
		let html = [];
		if(label[mediaType]['media'] != undefined && label[mediaType]['media'] != '') {
			html.push(
				<Col id="media-select-wrap">
					<Form.Label>{label[mediaType]['media']}</Form.Label>					
						<AsyncSelect className="async-select required" onChange={onChangeMediaHandle} name="media_id" loadOptions={mediaLoadOptions} defaultOptions={true} cacheOptions={false} option={[]} isClearable={true} key={mediaType+mediaDefaultValue} defaultValue={mediaDefaultValue} />					
				</Col>
			)
		}

		if(label[mediaType]['program'] != undefined && label[mediaType]['program'] != '') {
			html.push(
				<Col xs={5}>
					<Form.Group controlId="program">
						<Form.Label>{label[mediaType]['program']}</Form.Label>
							<AsyncSelect name="program_id" loadOptions={programLoadOptions} defaultOptions={true} isDisabled={disabled} key={programDefaultValue} defaultValue={programDefaultValue} />
					</Form.Group>
				</Col>
			)
		}

		if(html.length != 0) {
			return (
				<>				
					<Row>
						{html}
					</Row>
					<br />
				</>
			)
		}
	}

	const getFields = () => {

		if(mediaType == '') return 
		let html = [];
		let fullHtml = [];

		if(label[mediaType]['pub_placements'] != '') {
			html.push(
				<Col>
					<Form.Group controlId="pub_placements">
						<Form.Label>{label[mediaType]["pub_placements"]}</Form.Label>
						<Form.Control type="text" name="pub_placements" placeholder="" onChange={calculateValue} className="required" />
					</Form.Group>
				</Col>
			)
		}

		if(label[mediaType]['pub_impr_circ'] != '') {
			html.push(
				<Col>
					<Form.Group controlId="pub_impr_circ">
						<Form.Label>{label[mediaType]["pub_impr_circ"]}</Form.Label>
						<Form.Control type="text" name="pub_impr_circ" placeholder="" className="required" />
					</Form.Group>
				</Col>
			)
		}

		if(label[mediaType]['pub_value'] != '') {
			html.push(
				<Col>
					<Form.Group controlId="pub_value">
						<Form.Label>{label[mediaType]["pub_value"]}</Form.Label>
						<Form.Control type="text" name="pub_value" placeholder="" className="required" />
					</Form.Group>
				</Col>
			)
		}

		if(html.length != 0) {
			if(isImport != 1 && showFillDefaultValueButton) {
				fullHtml.push(
					<>
						<Row>
							<Col>
								<Button size="xs" onClick={fillDefaultValues} variant="warning">Fill default values</Button><br /><br />
							</Col>
						</Row>
					</>
				)
			}			
			fullHtml.push(<><Row>{html}</Row><br /></>)
		}

		// for(let row of fields) {
		// 	html = [];			
		// 	for(let field of row) {
		// 		if(label[mediaType][field] != '') {
		// 			html.push(
		// 				<Col>
		// 					<Form.Group controlId={field}>
		// 						<Form.Label>{label[mediaType][field]}</Form.Label>
		// 						<Form.Control type="text" name={field} placeholder="" />
		// 					</Form.Group>
		// 				</Col>
		// 			)
		// 		}				
		// 	}
			
		// }
		return fullHtml
	}

	const onClose = () => {
		let t = setTimeout(() => {
			$('#pub_time, #pub_date').removeClass()
							.addClass('form-control')
							.attr('readonly', false)
			$('#pub_time, #pub_date').parent().removeClass()
			$('#pub_time, #pub_date').parent().parent().removeClass()
		}, 50)
	}

	const getDateDaypartRow = () => {
		if(mediaType == '') return 
		let html = [];

		if(label[mediaType]['pub_date'] != undefined && label[mediaType]['pub_date'] != '') {
			html.push(
				<Col xs={3}>
					<Form.Group controlId="pub_date">
						<Form.Label>{label[mediaType]['pub_date']}</Form.Label><br />
						<MuiPickersUtilsProvider utils={DateFnsUtils} >
						<DatePicker
							onChange={date => handleDateChange(date)}
							format="MM/dd/yyyy"
							value={selectedDate}
							id="pub_date"
							name="pub_date"
							disabled={disabled}
							className="required"
						/> 
						</MuiPickersUtilsProvider>
					</Form.Group>
				</Col>
			)
		}

		if(label[mediaType]['pub_time'] != undefined && label[mediaType]['pub_time'] != '') {
			html.push(
				<Col xs={3}>
					<Form.Group controlId="pub_time">
						<Form.Label>{label[mediaType]['pub_time']}</Form.Label><br />
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<TimePicker
								ampm={false}
								views={["hours", "minutes", "seconds"]}
								format="hh:mm:ss"
								value={selectedTime}
        						onChange={time => handleTimeChange(time)}
								onClose={onClose}
								name="pub_time"
								id="pub_time"
								className='form-control'
								disabled={disabled}
							/>
						</MuiPickersUtilsProvider>
					</Form.Group>
				</Col>
			)
		}

		if(label[mediaType]['pub_type'] != undefined && label[mediaType]['pub_type'] != '') {
			html.push(
				<Col xs={3}>
					<Form.Group controlId="pub_type">
						<Form.Label>{label[mediaType]['pub_type']}</Form.Label><br />
						<Form.Control name="pub_type" as="select">
							<option value="">-- Select Daypart --</option>
							<option value="EM">EM</option>
							<option value="EF">EF</option>
							<option value="DT">DT</option>
							<option value="LE">LE</option>
							<option value="LN">LN</option>
							<option value="PT">PT</option>
						</Form.Control>
					</Form.Group>
				</Col>
			)
		}

		if(html.length != 0) {
			return (
				<>				
					<Row>
						{html}
					</Row>
					<br />
				</>
			)
		}
	}

	const calculateValue = () => {
		// airings for TV, placements for print
		let pubPlacements = Number(document.querySelector('#pub_placements').value)
		
		if(rateImprs.rate != 0)
			document.querySelector('#pub_value').value = NumberHelper.round(pubPlacements * rateImprs.rate, 2)

		if(rateImprs.impression != 0)
			document.querySelector('#pub_impr_circ').value = NumberHelper.round(pubPlacements * rateImprs.impression, 2)
	}

	return (
		<Form onSubmit={SubmitAction} style={{width: "60%"}} id="result-form">
			{params.result_id?
				<Form.Control type="hidden" name="id" value={params.result_id} />
			:''}
			<Row>
				<Col xs={8}>
					<SelectBox className="required" url={Global['apiHost'] + '/api/form/clients'} label="Client" name="client_id" defaultValue={client} onChange={onChangeHandler} disabled={disabled} />
				</Col>
			</Row>
			<br />
			<Row>
				<Col>                
					<SelectBox url={Global['apiHost'] + '/api/form/campaigns?client_id=' + client} label="Campaign" defaultValue={campaign} name="campaign_id" onChange={onChangeHandler} disabled={disabled} />
				</Col>
				<Col xs={4}>                
					<Form.Group controlId="campaign_id">
						<Form.Label>Media Type</Form.Label>
						<Form.Control name="media_type" as="select" onChange={onChangeMediaType} value={mediaType} disabled={disabled} className="required">
							<option value="">-- Select Media type --</option>
							<option value="Television">Television</option>
							<option value="LocalCable">Local Cable</option>
							<option value="NationalCable">National Cable</option>
							<option value="Radio">Radio</option>
							<option value="Newspaper">Newspaper</option>
							<option value="Magazine">Magazine</option>
							<option value="Ooh">OOH</option>
						</Form.Control>
						{disabled?<Form.Control type="hidden" id="media_type_hidden" name="media_type" value={mediaType} />:''}
					</Form.Group>
				</Col>
			</Row>
			<br />
			<Row>						
				<Col>                
					<SelectBox className="required" url={Global['apiHost'] + '/api/form/commercials?campaign=' + campaign + '&media_type=' + mediaType} defaultValue={commercial} label="Commercials" name="advertisement_id" disabled={disabled} />
				</Col>				
			</Row>
			<br />
			{getMedia()}			
			{getFields()}
			{getDateDaypartRow()}
			<SubmitButton />
		</Form>
	);
};

export default ResultForm;
