import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './Header.module.scss';

const Header = (props) => (
  <header className="page-header" role="banner">
      <div className="page-logo">
          <a href="#" className="page-logo-link press-scale-down d-flex align-items-center position-relative" data-toggle="modal" data-target="#modal-shortcut">
              <img src="img/logo.jpg" alt="Fertile Data 3.0" aria-roledescription="logo" />
              <span className="page-logo-text mr-1">Fertile Data 3.0</span>
              <span className="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
              <i className="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300"></i>
          </a>                               
      </div>
      <div className="hidden-md-down dropdown-icon-menu position-relative">
          <a href="#" class="header-btn btn js-waves-off" data-action="toggle" data-class="nav-function-minify" title="Minify Navigation">
              <i className="ni ni-minify-nav"></i>
          </a>
      </div>                     
       {/* DOC: mobile button appears during mobile width  */}
      <div className="hidden-lg-up">
          <a href="#" className="header-btn btn press-scale-down" data-action="toggle" data-class="mobile-nav-on">
              <i class="ni ni-menu"></i>
          </a>
      </div>
      <h1 className="" style={{marginTop: "10px", textAlign: "center", width: "100%", padding: "0 5px"}}>
        {/* include the username */}
          FD Admin {/*props.clientName*/}{/*{auth()->user()->client} */}
      </h1>
      <div className="ml-auto d-flex">
          <div className="hidden-md-down">
              <a href="#" class="header-icon" title="FAQ">
                  <i className="fal fa-question-circle"></i>
              </a>
          </div>                            
          <div className="hidden-md-down">
              <a href="#" class="header-icon" title="Contact Administrator">
                  <i className="fal fa-envelope"></i>
              </a>
          </div>
          <div>
              <a href="#" data-toggle="dropdown" title="drlantern@gotbootstrap.com" className="header-icon d-flex align-items-center justify-content-center ml-2" aria-expanded="true">                                              
                  <span className="ml-1 mr-1 text-truncate text-truncate-header">PLOWSHARE</span>
                  <i className="ni ni-chevron-down"></i> 
              </a>
              <div className="dropdown-menu dropdown-menu-animated dropdown-lg">                                
                  <a className="dropdown-item fw-500 pt-3 pb-3" onclick="$('#logout').submit(); return false;">
                      <span data-i18n="drpdwn.page-logout">Logout</span>
                      <span className="float-right fw-n">PLOWSHARE</span>
                  </a>
                  {/* <form id="logout" method="post" action="/logout">@csrf</form> */}
              </div>
          </div>
      </div>
  </header>
);

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
