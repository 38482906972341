import React, { useState, useEffect } from 'react';
import {useParams, useHistory, Link} from 'react-router-dom';
import {Tabs, Tab} from 'react-bootstrap'
import Global from '../../../Global';
import DataTable from '../../UI/DataTable/DataTable';

const MediaRatesImprs = () => {

	const params = useParams()
	const [activeTab, setActiveTab] = useState('rates')

	return (
		<>
			<Tabs id="media-rates-imprs">         
				<Tab eventKey="media-rates" title="Rates">
					<br />
					{activeTab == 'rates'? 
						<DataTable 
							tableId="media-rates-table" 
							url={Global['apiHost'] + '/api/media/get/rates/' + params.media_id} 
							options={false}
							paging={false}
							deleteOption={false}
						/>
					:null}
					
				</Tab>
				<Tab eventKey="media-imprs" title="Impressions">
					<br />
					<DataTable 
						tableId="media-imprs-table" 
						url={Global['apiHost'] + '/api/media/get/imprs/' + params.media_id} 
						options={false}
						paging={false}
						deleteOption={false}
					/>
				</Tab>
			</Tabs>
		</>
	);
};

export default MediaRatesImprs;
