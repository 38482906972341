import React, {useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import Breadcrumb from '../components/UI/Breadcrumb/Breadcrumb';
import Subheader from '../components/UI/Subheader/UI/Subheader';
import Panel from '../components/UI/Panel/UI/Panel';
import Layout from '../components/Layout/Master/Master';
import CampaignForm from '../components/Modules/Campaigns/CampaignForm';
import ClientDropdown from '../components/Modules/Clients/ClientDropdown';

const CampaignFormPage = () => {
    const params = useParams()
    return (
        <Layout>
            <Breadcrumb name={params.campaign_id?"Edit Campaign":"Add Campaign"} />
            <Subheader title={params.campaign_id?"Edit Campaign":"Add Campaign"} />
            {!params.campaign_id?
            <Panel title="Change Client">
                <ClientDropdown pathName="new-campaign" url="https://fdmvp.azrdev.psadirector.net/test/get-clients" />
            </Panel>
            :''}
            <Panel title={params.campaign_id?"Edit Campaign":"Add Campaign"}>
                <CampaignForm />
            </Panel>
        </Layout>
    )
}

export default CampaignFormPage;