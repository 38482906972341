import React, {useEffect, useState, useRef} from 'react';
import '../../../common/css/datagrid/datatables/datatables.bundle.css';
import {useParams, useHistory, Link} from 'react-router-dom';
import {Tabs, Tab} from 'react-bootstrap'
import Global from '../../../Global';
import axios from '../../Helpers/axiosDefault';

const $ = require('jquery');
require("datatables.net-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis.js");
require("datatables.net-buttons/js/buttons.flash.js");
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");
require("datatables.net-colreorder-bs4");
require("datatables.net-responsive-bs4");
require("datatables.net-select-bs4");
var campaignId = 0;
var columns = [];
const ComCatTable = () => {

  const params = useParams();
  const history = useHistory();
  const [data, setData] = useState({});
  //const [campaignId, setCampaignId] = useState(0);
  const [columns, setColumns] = useState({});
  const FirstTab = useRef();
  const [active, setActive] = useState();  

  const mediaTypeTabs = {
    'AudioCommercialAdvertisement':'Radio',
    'OutdoorAdvertisement':'OOH',
    'PrintAdvertisement':'Print',
    'VideoCommercialAdvertisement':'TV/Cable'
  }

  const fetchData = () => {
    if(!params.campaign_id) return;    
    if(params.campaign_id == "undefined") {
        destroyDataTable();
        setData([]);
        return;
    }
    
    axios.get(Global.apiHost + '/api/commercial/get/all/' + params.campaign_id)
    .then(
      result => {
        setData([]);
        setData(result.data.data);
        setActive(Object.keys(result.data.data)[0]);             
        destroyDataTable();
        let columns = result.data.columns;
        columns[columns.length] = {"mRender": function ( data, type, row ) {
                let editHtml = '<button data-id="'+ row[0] +'" type="button" class="btn-xs mr-1 btn-edit float-right btn btn-primary waves-effect waves-themed">Edit</button>'
                let deleteHtml = '<button data-id="'+ row[0] +'" type="button" class="btn-xs mr-1 btn-delete float-right btn btn-danger btn-primary waves-effect waves-themed">Delete</button>'
                let duplicateHtml = '<button data-id="'+ row[0] +'" type="button" class="btn-xs mr-1 btn-duplicate float-right btn btn-info btn-primary waves-effect waves-themed">Duplicate</button>'
                return deleteHtml + editHtml + duplicateHtml
              }
        }
        
        for(let item in result.data.data) { 
            $('#table-' + item).DataTable({
                data: result.data.data[item],
                columns: columns,
                destroy: true,
                paging: false,
                drawCallback: function(settings, json) {
                  var path = window.location.pathname.replace(Global.basename, '')
                  $('#table-' + item).find('.btn-edit').on('click', function() {      
                    history.push(path + '/edit/' + $(this).attr('data-id'));
                  });
                  $('#table-' + item).find('.btn-delete').on('click', function() {
                    if(confirm('Are you sure you want to delete this Commercial?')) {
                      //history.push(path + '/delete1/' + $(this).attr('data-id'));
                      axios.post(Global.apiHost + '/api/commercial/delete/' + $(this).attr('data-id'))
                      $(this).parent().parent().remove()
                    }

                      
                  });
                  $('#table-' + item).find('.btn-duplicate').on('click', function() {
                    history.push(path + '/add/' + $(this).attr('data-id'));
                  });
                }
            });
        } 
      }
    )
  }

  const destroyDataTable = () => {
    $('.data-table-comercials').each(function() {
        if($('#'+$(this).attr('id')).hasClass('dataTable')) {
            $(this).DataTable().destroy();
            $(this).html('');
        }
    });
  }

  useEffect(() => {                                 
      fetchData();                                      
  }, [params.campaign_id])

  const onChangeTab = (k, e) => {
    // console.log(k);
    setActive(k);
  }

  return (
    <Tabs activeKey={active} defaultActiveKey="first" id="commercials-list" ref={FirstTab} onSelect={onChangeTab}>         
        {Object.keys(data).map((keyName, i) => (
            <Tab eventKey={keyName} title={mediaTypeTabs[keyName]}>
                <br />
                <table className="table table-bordered table-hover table-striped w-100 data-table-comercials" id={"table-" + keyName} ></table>
            </Tab>
        ))}
    </Tabs>
  )
}

ComCatTable.propTypes = {};
ComCatTable.defaultProps = {};

export default ComCatTable;
