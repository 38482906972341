import React from 'react';
import Breadcrumb from '../components/UI/Breadcrumb/Breadcrumb';
import Subheader from '../components/UI/Subheader/UI/Subheader';
import Panel from '../components/UI/Panel/UI/Panel';
import Layout from '../components/Layout/Master/Master';
import ClientDropdown from '../components/Modules/Clients/ClientDropdown';
import Campaigns from '../components/Modules/Campaigns/Campaigns';
import CampaignTools from '../components/Modules/Campaigns/CampaignTools';
import ClientTools from '../components/Modules/Clients/ClientTools';

const CampaignPage = () => {

    return (
    <Layout>
        <Breadcrumb name="Campaigns" />
        <Subheader title="Campaigns" />
        <Panel tools={<ClientTools showEdits="true" />} title="Clients">
            <ClientDropdown pathName="campaigns" />
        </Panel>
        <Panel tools={<CampaignTools />} title="Campaigns">
            <Campaigns />
        </Panel>
    </Layout>
    )
}

export default CampaignPage;