import React, { useState, useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import {Form, Button, Row, Col} from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import SelectBox from '../../UI/Form/SelectBox';
import Global from '../../../Global';

const FilterForm = (props) => {

	let [client, setClient] = useState('')
	let [campaign, setCampaign] = useState('')
	let [mediaType, setMediaType] = useState('')
	let [dateFilter, enableDateFilter] = useState(false)

	const onChangeHandler = (e) => {
		if(e.target.name == 'client_id') {
			setClient(e.target.value)
			setCampaign('')
			document.querySelector('#campaign_id').value = ''
		}
		if(e.target.name == 'campaign_id') {			
			setCampaign(e.target.value)
			document.querySelector('#advertisement_id').value = ''
		}
		if(e.target.name == 'media_type') setMediaType(e.target.value)
	}

	const toggleDateFilter = (e) => {
		if(e.target.checked) enableDateFilter(true)
		else enableDateFilter(false)
	}

	return (
		<Form onSubmit={props.onSubmit} id="results-filter">
			<Row>
				<Col xs={3}>                
					<Form.Group controlId="media_type">
						<Form.Label>Media Type</Form.Label>
						<Form.Control name="media_type" id="media_type" as="select" onChange={onChangeHandler}>
							<option value="">-- Select Media type --</option>
							<option value="Television">Television</option>
							<option value="NationalCable">National Cable</option>
							<option value="LocalCable">Local Cable</option>
							<option value="Radio">Radio</option>
							<option value="Newspaper">Newspaper</option>
							<option value="Magazine">Magazine</option>
							<option value="OOH">OOH</option>
						</Form.Control>
					</Form.Group>
				</Col>
				<Col>                
					<SelectBox url={Global['apiHost'] + '/api/form/clients'} label="Client" name="client_id" onChange={onChangeHandler} />
				</Col>
				<Col>    
					<SelectBox url={Global['apiHost'] + '/api/form/campaigns?client_id=' + client} label="Campaign" onChange={onChangeHandler} name="campaign_id" />
				</Col>				
			</Row>
			<br />
			<Row>						
				<Col xs={4}>      
					<SelectBox url={Global['apiHost'] + '/api/form/commercials?campaign=' + campaign + '&media_type=' + mediaType} label="Commercials" name="advertisement_id" />
				</Col>	
				<Col xs={3} md={2} lg={2}>      
					<Form.Label>Date Filter</Form.Label>
					<Form.Check id="custom-switch" label="Filter by Date" onClick={toggleDateFilter} />
				</Col>	
				{dateFilter?
				<Col xs={4}> 
					<Form.Group controlId="name">						
						<Form.Label>Date Range</Form.Label>
						<DateRangePicker>
							<input type="text" name="date_range" className="form-control" />
						</DateRangePicker>
					</Form.Group>
				</Col> 			
				:''}
			</Row>
			<br />
			<Row >
				<Col>                
					<Form.Group controlId="description">
						<Button type="submit" variant="primary">Submit</Button>
					</Form.Group>
				</Col>     
			</Row>	
		</Form>
	);
};

const FilterFormA = withRouter(FilterForm)

export default FilterFormA
