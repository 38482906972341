import React from 'react';

const Panel = (props) => {

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="panel filter-panel">
                    <div className="panel-hdr">
                        <h2 style={{cursor: "pointer"}} data-action={props.collapse?"panel-collapse":""} data-offset="0,10">
                            {props.title}
                        </h2>
                        <div className="panel-toolbar">
                            {props.tools}
                            {props.collapse?
                                <button className="btn btn-panel"  data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                            :''}
                        </div>
                    </div>
                                
                    <div className="panel-container show">
                        <div className="panel-content">
                            {props.children}
                        </div>
                    </div>     
                </div>
            </div>
        </div> 
    )
};

Panel.propTypes = {};

Panel.defaultProps = {};

export default Panel;
