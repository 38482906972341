import React, { useState, useEffect } from 'react';
import Breadcrumb from '../components/UI/Breadcrumb/Breadcrumb';
import Subheader from '../components/UI/Subheader/UI/Subheader';
import Panel from '../components/UI/Panel/UI/Panel';
import Layout from '../components/Layout/Master/Master';
import DataTable from '../components/UI/DataTable/DataTable';
import FilterForm from '../components/Modules/Markets/FilterForm';
import Global from '../Global';
import MarketForm from '../components/Modules/Markets/MarketForm';
import { useParams } from 'react-router-dom';
import MarketTools from '../components/Modules/Markets/MarketTools';

const MarketsPage = () => {

	const params = useParams();
	let baseUrl = Global['apiHost'] + "/api/market/get-all"
	const [filterUrl, setFilterUrl] = useState(baseUrl)

	const handleFilterSubmit = (e) => {
		e.preventDefault()
		let data = $(e.target).serialize()
		setFilterUrl(baseUrl + '?' + data)		
	}

	const getMarketPanel = () => {
		if(params.event == 'add') {
			return (
				<Panel title="Add Market">
					<MarketForm />
				</Panel>
			)
		} else if(params.event == 'edit') {
			return (
				<Panel title="Edit Market">
					<MarketForm />
				</Panel>
			)
		} else {
			return (
				<>				
					<Panel title="Filter Markets">
						<FilterForm onSubmit={handleFilterSubmit} />
					</Panel>
					<Panel title="Market List" tools={<MarketTools />}>
						<DataTable 
							tableId="market-table" 
							url={filterUrl} 
							options="true"
							paging={false}
							deleteOption={false}
						/>
					</Panel>
				</>
			)
		}
	}

	return (
		<Layout>
			<Breadcrumb name="Markets" />
			<Subheader title="Markets" />
			{getMarketPanel()}			
		</Layout>
	);
};

export default MarketsPage;
