import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../components/UI/Breadcrumb/Breadcrumb';
import Subheader from '../components/UI/Subheader/UI/Subheader';
import Panel from '../components/UI/Panel/UI/Panel';
import Layout from '../components/Layout/Master/Master';
import Global from '../Global';
import DataTable from '../components/UI/DataTable/DataTable';
import ResultForm from '../components/Modules/Results/ResultForm';
import FilterForm from '../components/Modules/Results/FilterForm';
import ResultTools from '../components/Modules/Results/ResultTools';

const ResultsPage = () => {

	const params = useParams()
	let baseUrl = Global['apiHost'] + "/api/results/get-all"
	const [filterUrl, setFilterUrl] = useState('')
	const [enableDelete, setEnableDelete] = useState(false)
	let enableDeleteFor = ['Newspaper', 'Magazine', 'OOH']

	const handleFilterSubmit = (e) => {
		e.preventDefault()
		if(enableDeleteFor.indexOf($('#media_type').val()) != -1) {
			setEnableDelete(true)
		} else {
			setEnableDelete(false)
		}
		let data = $(e.target).serialize()
		setFilterUrl(baseUrl + '?' + data)		
	}

	const getResultsPanel = () => {
		if(params.event == 'add') {
			return (
				<Panel title="Add Results">
					<ResultForm />
				</Panel>
			)
		} else if(params.event == 'edit') {
			
			return (
				<Panel title="Edit Results">
					<ResultForm />
				</Panel>
			)
		} else {
			return (
				<>				
					<Panel title="Filter Results" tools={<ResultTools />}>
						<FilterForm onSubmit={handleFilterSubmit} />
					</Panel>
					{filterUrl != ''?
						<Panel title="Results List">
							<DataTable 
								tableId="results-table" 
								url={filterUrl} 
								options="true"
								deleteOption={enableDelete}
								deleteUrl="http://fertiledata4.test/api/results/delete"
							/>
						</Panel>
					:''}
					
				</>
			)
		}
	}

	return (
		<Layout>
			<Breadcrumb name="Results" />
			<Subheader title="Results" />
			{getResultsPanel()}
		</Layout>
	);
};

export default ResultsPage;
