import React, {useEffect, useState, useRef} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useParams, useHistory} from 'react-router-dom';
import {Form, Button, Row, Col} from 'react-bootstrap';
import SubmitButton from '../../UI/Form/SubmitButton';
//import DatePicker from 'react-datepicker';
import Global from '../../../Global';
import axios from '../../Helpers/axiosDefault';
import '../../../common/css/formplugins/bootstrap-datepicker/bootstrap-datepicker.css';
import Validate from '../../UI/Form/Validate/Validate';
import $ from 'jquery';
//const $ = require('jquery');
require('../../../common/js/formplugins/bootstrap-datepicker/bootstrap-datepicker.js');


const CampaignForm = () => {

    const params = useParams()
    const history = useHistory()

    const SubmitAction = (e) => {
        e.preventDefault()
        if(!Validate.checkForm()) return false;

        let url = Global['apiHost'] + '/api/campaign/save'
        let formData = new FormData(document.querySelector('#campaign-form'))
        axios.post(url, formData).then(response => {
            history.push('/campaigns/' + params.client_id)
        })        
        return false
    }

    const loadCampaignData = () => {
        let url = Global['apiHost'] + '/api/campaign/get/' + params.campaign_id
        axios.get(url).then(response => {
            $('input.form-control[type=text]').each(function() {
                $(this).val(response.data[$(this).attr('name')])
            })
            initDatePicker()
        })
    }

    const initDatePicker = () => {        
        $('#date_effective').datepicker();
        $('#date_ending').datepicker();
    }

    useEffect(() => {     
        if(params.campaign_id) {
            loadCampaignData();
        } else {
            initDatePicker();
        }        
    })

    const editId = () => {
        if(params.campaign_id) {
            return <Form.Control type="hidden" name="id" value={params.campaign_id} />
        }
    }

    return (
        <Form onSubmit={SubmitAction} id="campaign-form" style={{width: "60%"}}>
            <Form.Control type="hidden" name="client_id" value={params.client_id} />
            {editId()}
            <Row >
                <Col>                
                    <Form.Group controlId="campaign">
                        <Form.Label>Campaign Name</Form.Label>
                        <Form.Control type="text" name="campaign" placeholder="Campaign Name" className="required" />
                    </Form.Group>
                </Col>   
            </Row>
            <br />
            <Row >
                <Col>                
                    <Form.Group controlId="date_effective">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control type="text" id="date_effective" name="date_effective" placeholder="Start Date" className="required" />                    
                    </Form.Group>
                </Col>   
                <Col>                
                    <Form.Group controlId="date_ending">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control type="text" id="date_ending" name="date_ending" placeholder="End Date" className="required" />
                    </Form.Group>
                </Col>   
            </Row>
            <br />
            <Row >
                <Col>                
                    <Form.Group controlId="affinity">
                        <Form.Label>Affinity</Form.Label>
                        <Form.Control type="text" id="affinity" name="affinity" placeholder="Affinity" />
                    </Form.Group>
                </Col>   
                <Col>                
                    <Form.Group controlId="affinity_code">
                        <Form.Label>Affinity Code</Form.Label>
                        <Form.Control type="text" name="affinity_code" placeholder="Affinity Code" />
                    </Form.Group>
                </Col>   
            </Row>
            <br />
            <Row >
                <Col>                
                    <Form.Group controlId="psa_score">
                        <Form.Label>PSA Score</Form.Label>
                        <Form.Control type="text" name="psa_score" placeholder="PSA Score" />
                    </Form.Group>
                </Col>   
                <Col>                
                    <Form.Group controlId="psa_value">
                        <Form.Label>PSA Value</Form.Label>
                        <Form.Control type="text" name="psa_value" placeholder="PSA Value" />
                    </Form.Group>
                </Col>   
            </Row>
            <br />
            <Row >
                <Col>                
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control type="text" name="description" placeholder="Description" />
                    </Form.Group>
                </Col>     
            </Row>
            <br />
            <SubmitButton />            
        </Form>

    )
}

CampaignForm.propTypes = {};
CampaignForm.defaultProps = {};

export default CampaignForm;