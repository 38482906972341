import React, {useEffect, useState} from 'react';
import {useParams, useHistory, useNavigate} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumb from '../../UI/Breadcrumb/Breadcrumb';
import Subheader from '../../UI/Subheader/UI/Subheader';
import Panel from '../../UI/Panel/UI/Panel';
import Layout from '../../Layout/Master/Master';
import {Form, Button, Row, Col} from 'react-bootstrap';
import SubmitButton from '../../UI/Form/SubmitButton';
import Global from '../../../Global';
import axios from '../../Helpers/axiosDefault';
import Validate from '../../UI/Form/Validate/Validate';
import $ from 'jquery';

const ClientAdd = () => {

  const params = useParams();
  const [state, setState] = useState('start') 
  const history = useHistory()

  const SubmitAction = (e) => {
    e.preventDefault();
    if(!Validate.checkForm()) return false;
    let url = Global['apiHost'] + '/api/client/save';
    let formData = new FormData(document.querySelector('#client-form'));
    // if(document.querySelector('#logo').files[0] != undefined)
    //   formData.append('logo', document.querySelector('#logo').files[0]);

    
    axios.post(url, formData, {
       headers: {
         "Content-Type": "multipart/form-data",
       }
    }).then((response) => {
      history.push('/clients')
    }).catch((error) => {
      console.log(error);
    })
    e.preventDefault();
  }

  useEffect(() => {
    if(params.client_id != undefined) {
      fetchClientData()
    }
  })

  const fetchClientData = () => {
    let url = Global['apiHost'] + '/api/client/get/' + params.client_id
    axios.get(url).then(response => {
        $('input.form-control[type=text]').each(function() {          
          $(this).val(response.data[$(this).attr('name')])
        })
    })
  }

  const editId = () => {
    if(params.client_id) {
      return <Form.Control type="hidden" name="id" value={params.client_id} />
    }
  }
  
  return (    
    <Layout>
      <Breadcrumb name={params.client_id?"Edit Client":"Add a Client"} />
      <Subheader title={params.client_id?"Edit Client":"Add a Client"} />
      <Panel title={params.client_id?"Edit Client":"Add a Client"}>
      <Form id="client-form" onSubmit={SubmitAction} style={{width: "60%"}}>
      {editId()}
      <Row >
            <Col>          
                <Form.Group controlId="client">
                  <Form.Label>Client Name</Form.Label>
                  <Form.Control type="text" name="client" className="required" placeholder="Client Name" />
                </Form.Group>
            </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control type="text" name="city" placeholder="City" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control type="text" name="state" placeholder="State" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="zip">
              <Form.Label>Zip</Form.Label>
              <Form.Control type="text" name="zip" placeholder="Zip" />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Group controlId="contact">
              <Form.Label>Contact</Form.Label>
              <Form.Control type="text" name="contact" placeholder="Contact" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" name="title" placeholder="Title" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control type="text" name="phone" placeholder="Phone" />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" name="email" placeholder="Email" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="Fax">
              <Form.Label>Fax</Form.Label>
              <Form.Control type="text" name="fax" placeholder="Fax" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="website">
              <Form.Label>Website</Form.Label>
              <Form.Control type="text" name="website" placeholder="Website" />
            </Form.Group>
          </Col>
        </Row>
        {/* <br />
        <Row>
          <Col>
          <Form>
            <Form.Group>
              <Form.File id="logo" label="Client Logo" />
            </Form.Group>
          </Form>
          </Col>
        </Row> */}
        <br />
        <SubmitButton />
      </Form>    
      </Panel>
    </Layout>  
  )
};

export default ClientAdd;
