import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import axios from '../../Helpers/axiosDefault';
import Global from '../../../Global';


const CommercialSize = (props) => {	

	let [size, setSize] = useState([])

	const videoRadioLength = () => {
		return(
			<>
				<Form.Label>Duration</Form.Label>
				<Form.Control as="select" name="length" className={props.className}>
					<option value="15">15</option>
					<option value="30">30</option>
					<option value="60">60</option>
					<option value="90">90</option>
					<option value="95">95</option>
					<option value="120">120</option>
				</Form.Control>
			</>
		)
	}

	const printSize = () => {
		return (
			<>
				<Form.Label>{props.label?props.label:'Option'}</Form.Label>
				<Form.Control as="select" name="length" className={props.className}>
					<option value="30 Sheet">30 Sheet</option>
					<option value="Digital">Digital</option>
					<option value="Digital Magazine">Digital Magazine</option>
					<option value="Magazine">Magazine</option>
					<option value="Newspaper">Newspaper</option>
					<option value="2 Full Page one 1/2 Page">2 Full Page one 1/2 Page</option>
				</Form.Control>
			</>
		)
	}

	const OOHSize = () => {
		return(
			<>
				<Form.Label>Size</Form.Label>
				<Form.Control type="text" name="length" placeholder="Size" className={props.className} />
			</>
		)
	}

	const getSize = (data) => {
		return (
			<>
				<Form.Label>{props.label?props.label:'Option'}</Form.Label>
				<Form.Control as="select" name="length" className={props.className}>
					<option value="">--Select {props.label?props.label:'Option'}--</option>
					{Object.keys(size).map((item, key) => {
						let selected = ''
						if(item == props.selected) selected = 'selected'
						return (<option selected={selected} value={item}>{item}</option>)
					})}
				</Form.Control>
			</>
		)
	}

	useEffect(() => {
		if(props.category != undefined) {
			axios.get(Global['apiHost'] + '/api/form/commercial/size/' + props.category)
			.then(response => {
				setSize(response.data)
			})
		}		
	}, [props.category])

	return (
		<>
			{getSize()}
		</>
	);
};

export default CommercialSize;
