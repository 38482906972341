import React from 'react';
import {useParams, useHistory} from 'react-router-dom';
import Breadcrumb from '../components/UI/Breadcrumb/Breadcrumb';
import Subheader from '../components/UI/Subheader/UI/Subheader';
import Panel from '../components/UI/Panel/UI/Panel';
import Layout from '../components/Layout/Master/Master';
import ClientDropdown from '../components/Modules/Clients/ClientDropdown';
import CampaignsDropdown from '../components/Modules/Campaigns/CampaignsDropdown';
import ComCatTable from '../components/Modules/Commercials/ComCatTable';
import ClientTools from '../components/Modules/Clients/ClientTools';
import CampaignTools from '../components/Modules/Campaigns/CampaignTools';
import CommercialTools from '../components/Modules/Commercials/CommercialTools';
import CommercialForm from '../components/Modules/Commercials/CommercialForm';

const CommercialPage = () => {
    const params = useParams();
    if(params.event == 'add') {
        console.log('events are showing');
    }    

    const getCommercialPanel = () => {
        if(params.event == 'add') {
            return (
                <Panel title="Add New Commercial">
                    <CommercialForm />
                </Panel>
            )
        } if(params.event == 'edit') {
            return (
                <Panel title="Edit Commercial">
                    <CommercialForm />
                </Panel>
            )
        } else {
            return (
                <Panel tools={<CommercialTools showEdits="true" />} title="Commercials">
                    <ComCatTable />
                </Panel>
            )
        }
    }

    return (
        <Layout>
            <Breadcrumb name="Commercials" />
            <Subheader title="Commercials" />
            <Panel tools={<ClientTools showEdits="true" />} title="Change Client">
                <ClientDropdown pathName="commercials" />
            </Panel>
            <Panel tools={<CampaignTools showEdits="true" />} title="Change Campaign">
                <CampaignsDropdown />
            </Panel>
            {getCommercialPanel()}
        </Layout>
    )
}

export default CommercialPage;