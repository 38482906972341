import React, {useEffect, useRef} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import '../../../common/css/datagrid/datatables/datatables.bundle.css';
import Global from '../../../Global';
import './styles.css';
import axios from '../../Helpers/axiosDefault';
const $ = require('jquery');


require("datatables.net-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis.js");
require("datatables.net-buttons/js/buttons.flash.js");
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");
require("datatables.net-colreorder-bs4");
require("datatables.net-responsive-bs4");
require("datatables.net-select-bs4");

/*
  options
  props.options = true / false
  props.url
  props.tableId
  props.checkbox
  props.paging
  props.pageLength
  props.callBack
  props.editOption
  props.archiveOption
  props.deleteOption
  props.deleteUrl
*/

const DataTable = (props) => {

  const history = useHistory();

  const deleteRecord = (obj) => {
    var id = $(obj).attr('data-id')
    var path = window.location.pathname.replace(Global.basename, '')
    if(confirm('Are you sure you want to delete this record?')) {
      if(props.deleteUrl) {
        $.post(props.deleteUrl, {'id': id})
      } else {        
        $.post(Global.apiHost + path + '/delete', {'id': id})
      }       
      $(obj).parent().parent().remove()       
    } 
  }

  const initTable = (result) => {
      if($('#' + props.tableId).hasClass('dataTable')) {
        $('#' + props.tableId).DataTable().destroy();
        $('#' + props.tableId).html('')
      }
      let columns = result.columns;
      let searchOption = true      
      
      if(props.options) {
        columns[columns.length] = {"mRender": function ( data, type, row ) {
            let editHtml = '<button data-id="'+ row[0] +'" type="button" class="btn-xs mr-1 btn-edit float-right btn btn-primary waves-effect waves-themed">Edit</button>';
            let deleteHtml = '<button data-id="'+ row[0] +'" type="button" class="btn-xs mr-1 btn-delete float-right btn btn-danger btn-primary waves-effect waves-themed">Delete</button>';
            let archiveHtml = '<button data-id="'+ row[0] +'" type="button" class="btn-xs mr-1 btn-archive float-right btn btn-danger btn-primary waves-effect waves-themed">Archive</button>';
            let html = '';
            if(props.archiveOption) html += archiveHtml
            if(props.deleteOption != false) html += deleteHtml
            if(props.editOption != false) html += editHtml
            
            return html
          }
        }
      }

      /*
        pass column number and call back function
        c - column #, 
        props.checkbox = {['c':1, 'data-key': 'key', 'class': 'classname']}
        call back
        props.checkboxCallback
      */
      if(props.checkbox) {
        for(let x of props.checkbox) {
          columns[x['column']]['mRender'] = function(data, type, row) {
            let checked = 'checked'
            if(row[x['column']] != 1) {
              checked = ''
            }
            if(x['class'] == undefined) x['class'] = ''
            //return '<div class="custom-control custom-switch"><input type="checkbox" id="custom-switch-'+row[0]+x['class']+'" data-id="'+row[0]+'" '+checked+'  class="custom-control-input '+x['class']+'" /><label class="custom-control-label" for="custom-switch-'+row[0]+x['class']+'"></label></div>'
            return '<input style="text-align: center" type="checkbox" data-id="'+row[0]+'" '+checked+' class="'+x['class']+'"  data-key="'+x['data-key']+'" />'
          }
        }
      }

      if(result.data.length == 0) {
        $('#' + props.tableId).DataTable({
          data: {},
          columns: columns 
        })
        return false; 
      }

      if(props.searchOption == false) {
        searchOption = false
      }

      $('#' + props.tableId).DataTable({
        data: result.data,
        columns: columns,
        destroy: true,
        bFilter: searchOption,
        paging: props.paging,
        pageLength: props.pageLength,
        drawCallback: function(settings, json) {
          var path = window.location.pathname.replace(Global.basename, '')
          $('#' + props.tableId).find('.btn-edit').off( 'click').on('click', function() {      
            //console.log(path);    
            history.push(path + '/edit/' + $(this).attr('data-id'));
          });
          $('#' + props.tableId).find('.btn-delete').off( 'click').on('click', function() {
            deleteRecord($(this))            
          });

          if(props.checkbox) {
            $('input[type=checkbox]').off( 'click').on('click', function() {
              if(typeof props.checkboxCallback == 'function') {
                props.checkboxCallback($(this));
              }
            })
          }

          if(props.callback != undefined) {
            if(typeof props.callback == 'function') {
              props.callback();
            }
          } 
        },
        createdRow: function (row, data, dataIndex) {
          if(props.createdRow != undefined) {
            if(typeof props.createdRow == 'function') {
              props.createdRow(row, data, dataIndex);
            }
          } 
        }
      });
  }

  const fetchData = () => {    
    axios.get(props.url)
    .then(
      result => {
        initTable(result.data)   
      }
    )
  }

  useEffect(() => {
    if(props.url) {
      fetchData()
    } else if(props.result) {      
      initTable(props.result)
    }
  })

  return (
    <>
      <table className="dt-clients table table-bordered table-hover table-striped w-100" id={props.tableId} ></table> 
    </>    
  )
}

DataTable.propTypes = {}; 
DataTable.defaultProps = {};

export default DataTable;
