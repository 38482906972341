import React from 'react';

const Breadcrumb = (props) => (
  <ol className="breadcrumb page-breadcrumb">
      <li className="breadcrumb-item"><a href="javascript:void(0);">FertileData 3.0</a></li>
      <li className="breadcrumb-item">{props.name}</li>
      <li className="position-absolute pos-top pos-right d-none d-sm-block"><span className="js-get-date"></span></li>
  </ol>
);

Breadcrumb.propTypes = {};

Breadcrumb.defaultProps = {};

export default Breadcrumb;
