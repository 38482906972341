import './styles.css'

let check = (e) => {
	let result = true
	e.classList.remove('invalid')
	if(e.value == '') {
		result = false
		if(e.parentElement.className.includes('async-select')) {			
			e.parentElement.querySelector('div').classList.add('invalid')
			console.log(e.parentElement.querySelector('div').classList)
		} else {	
			e.classList.add('invalid')
		}
	} else {
		if(e.parentElement.className.includes('async-select')) {
			e.parentElement.querySelector('div').classList.remove('invalid')
		} else {
			e.classList.remove('invalid')
		}
	}

	e.onchange = function(el) {		
		if(el.target.value != '') {
			if(el.target.parentElement.className.includes('async-select')) {
				el.target.parentElement.querySelector('div').classList.remove('invalid')
			} else {
				el.target.classList.remove('invalid')
			}
		}
	}
	return result
}

// validates entire form that has the required class
let checkForm = () => {
	let result = true;	
	document.querySelectorAll('.form-control.required').forEach((e) => {
		if(!Validate.check(e)) result = false
	})

	document.querySelectorAll('.async-select.required > input').forEach((e) => {
		if(!Validate.check(e)) result = false
	})
	return result
}

// validates selected elements in the form of array.
// required the name of the field and not the ID
let checkArray = (items) => {
	let result = true;	
	items.forEach((name) => {
		if(!Validate.check(document.querySelector('[name='+name+']'))) result = false
	})
	return result
}

let Validate = {
	check: check,
	checkForm: checkForm,
	checkArray: checkArray
}

export default Validate;
