import axios from 'axios';

const axiosDefault = axios.create({
			//withCredentials: true,
			//crossDomain: true,
			// headers: {
			// 	Accept: "application/json",
			// 	"Content-Type": "application/json",
			// }
	});

export default axiosDefault;
