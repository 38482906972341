import React, { useState, useEffect } from 'react';
import {Form, Button, Row, Col} from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import SelectBox from '../../UI/Form/SelectBox';
import Global from '../../../Global';
import axios from '../../Helpers/axiosDefault';

const FilterForm = (props) => {

	const [mediaType, setMediaType] = useState('')

	const onChangeMediaType = (e) => {
		setMediaType(e.target.value)
	}

	const handleSubmit = (e) => {
		let formData = new FormData(e.target)
		let  url = Global['apiHost'] + '/api/media/search'
		axios.post(url, formData)
			.then(res => {
				
			})
		e.preventDefault()
		return false
	}

	const getMarket = () => {
		let allow = ['Television', 'LocalCable', 'Radio']
		if(allow.includes(mediaType)) {
			return (
				<Col>    
					<SelectBox url={Global['apiHost'] + '/api/form/markets/by-type/' + mediaType} label="Market" name="market_id" />            
				</Col>
			)			
		}
	}

	return (
		<Form style={{width: "70%"}} id="media-search" onSubmit={props.onSubmit}>
			<Row >
				<Col>                
					<Form.Group controlId="search">
						<Form.Label>Search Media</Form.Label>
						<Form.Control type="text" name="search" placeholder="" />
					</Form.Group>
				</Col>   
				<Col>                
					<Form.Group controlId="media_type">
						<Form.Label>Media type</Form.Label>
						<Form.Control name="media_type" as="select" onChange={onChangeMediaType}>
							<option value="">-- Select Media type --</option>
							<option value="Television">Television</option>
							<option value="LocalCable">Local Cable</option>
							<option value="NationalCable">National Cable</option>
							<option value="Radio">Radio</option>
							<option value="Newspaper">Newspaper</option>
							<option value="Magazine">Magazine</option>
							<option value="OOH">OOH</option>
						</Form.Control>
					</Form.Group>
				</Col>   
				{getMarket()}
			</Row>
			<br />
			<Row >
				<Col>                
					<Form.Group controlId="description">
						<Button type="submit" variant="primary">Submit</Button>
					</Form.Group>
				</Col>     
			</Row>	
		</Form>
	);
};

export default FilterForm;
