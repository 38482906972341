import React, { useState, useEffect } from 'react';
import Breadcrumb from '../components/UI/Breadcrumb/Breadcrumb';
import Subheader from '../components/UI/Subheader/UI/Subheader';
import Panel from '../components/UI/Panel/UI/Panel';
import Layout from '../components/Layout/Master/Master';
import DataTable from '../components/UI/DataTable/DataTable';
import ClientTools from '../components/Modules/Clients/ClientTools';
import Global from '../Global';
import $ from 'jquery';
import axios from '../components/Helpers/axiosDefault';

const ClientsPage = () => {

	const onToggleCheckbox = (obj) => {
		let url = Global['apiHost'] + '/api/client/' + $(obj).attr('data-key');
		let value = 1
		if(!$(obj).prop('checked')) {
			value = 0
		}
		axios.post(url, {'v': value, 'id': $(obj).attr('data-id')})
	}

	return (
		<Layout>
			<Breadcrumb name="Clients" />
			<Subheader title="Clients" />
			<Panel title="Clients List" tools={<ClientTools />}>
				<DataTable 
					tableId="clients-table" 
					url={Global['apiHost'] + '/api/clients/get-all'}
					options="true"
					pageLength="100"
					checkbox={[
								{'column':2, 'data-key': 'locked'},
								{'column':3, 'data-key': 'show-impressions'},
								{'column':4, 'data-key': 'show-dashboard'}
							]}
					checkboxCallback={onToggleCheckbox}
					deleteOption={false}
				/>
			</Panel>			
		</Layout>
	);
};

export default ClientsPage;
