let host = window.location.hostname;
let apiUrl = 'https://fd4.fertiledata.com'
if(host == 'localhost') {
    apiUrl = 'http://localhost/FD4/Laravel/public'
}

let Global = {
    isAdmin: false,
    isShowDashboard: false,
    apiHost: apiUrl,
    basename: '/admin',
    dashboardURL: 'https://fd4.fertiledata.com/dashboard',
    mapDataUrl: 'https://code.highcharts.com/mapdata/countries/us/us-all.js'
};

export default Global;



// let host = window.location.hostname;
// let apiUrl = 'http://localhost/FD4/Laravel/public'
// if(host == 'localhost') {
//     apiUrl = 'http://localhost/FD4/Laravel/public'
// }

// let Global = {
//     isAdmin: false,
//     isShowDashboard: false,
//     apiHost: apiUrl,
//     basename: '/admin',
//     dashboardURL: 'https://fd3.fertiledata.com/dashboard',
//     mapDataUrl: 'https://code.highcharts.com/mapdata/countries/us/us-all.js'
// };

// export default Global; 