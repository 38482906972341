import React, {useEffect} from 'react';
import '../../../common/css/datagrid/datatables/datatables.bundle.css';
import {useParams, useHistory} from 'react-router-dom';
import DataTable from '../../UI/DataTable/DataTable';
import Global from '../../../Global';

const $ = require('jquery');
require("datatables.net-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis.js");
require("datatables.net-buttons/js/buttons.flash.js");
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");
require("datatables.net-colreorder-bs4");
require("datatables.net-responsive-bs4");
require("datatables.net-select-bs4");

const Campaigns = () => {

  const params = useParams();
  const history = useHistory();

  const fetchData = () => {    
    //if(!params.client_id) return;
    // fetch("https://fdmvp.azrdev.psadirector.net/test/get-campaigns/table/" + params.client_id)
    // .then(res => res.json())
    // .then(
    //   result => {
    //     if($('#dt-campaigns').hasClass('dataTable')) {
    //       $('#dt-campaigns').DataTable().destroy();
    //     }
    //     let columns = result.columns;
    //     columns[columns.length] = {"mRender": function ( data, type, row ) {
    //       return '<button data-id="'+ row[0] +'" type="button" class="btn-xs mr-1 btn-delete float-right btn btn-danger btn-primary waves-effect waves-themed">Delete</button>'
    //               + '<button data-id="'+ row[0] +'" type="button" class="btn-xs mr-1 btn-edit float-right btn btn-primary waves-effect waves-themed">Edit</button>'}
    //     }
    //     $('#dt-campaigns').DataTable({
    //       data: result.data,
    //       columns: columns,
    //       destroy: true
    //     });

        
      //}
    //)

    $('#dt-campaigns > tbody tr').each(function() {               
      $(this).children('td:eq(0)').on('click', function() {
        history.push('/commercials/'+params.client_id+'/'+$(this).html());
      });
    });
  }

  const initRowClick = () => {
    $('#dt-campaigns > tbody tr').each(function() {         
      $(this).children('td:eq(0)').on('click', function() {
        history.push('/commercials/'+params.client_id+'/'+$(this).html());
      });
    });
  }

  useEffect(() => {
    
  })

  return (
    <>
      <DataTable 
					tableId="dt-campaigns" 
					url={Global['apiHost'] + '/api/campaigns/get/all/' + params.client_id} 
          options="true"
          callback={initRowClick}
          paging={false}
          deleteOption={false}
				/>
      {/* <table className="dt-clients table table-bordered table-hover table-striped w-100" id="dt-campaigns" ></table>  */}
    </>
    
  )
}

Campaigns.propTypes = {};
Campaigns.defaultProps = {};

export default Campaigns;
