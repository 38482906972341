import React from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Button} from 'react-bootstrap';

const ClientTools = (props) => {

    const history = useHistory();
    const params = useParams();

    const addClient = () => {
        history.push('/clients/new');
    }

    const showAllClients = () => {
        history.push('/clients')
    }

    const editClient = () => {
        history.push('/clients/edit/' + params.client_id)
    }

    return (
        <>                        
            {props.showEdits?
                <> 
                    <Button variant="primary" className="btn-sm mr-1" type="button" onClick={editClient}>Edit Client</Button>
                    {/*<Button variant="primary" className="btn-sm mr-1 btn-danger" type="button">Delete Client</Button>*/}
                    <Button variant="primary" className="btn-sm mr-1 btn-info" type="button" onClick={showAllClients}>Show All Clients</Button>
                </>
            :''}            
            <Button variant="primary" className="btn-sm btn-success" type="button" onClick={addClient}>Add New Client</Button>            
        </>
    )    
}

export default ClientTools;