import React, { useState, useEffect } from 'react';
import Breadcrumb from '../components/UI/Breadcrumb/Breadcrumb';
import Subheader from '../components/UI/Subheader/UI/Subheader';
import Panel from '../components/UI/Panel/UI/Panel';
import Layout from '../components/Layout/Master/Master';
import DataTable from '../components/UI/DataTable/DataTable';
import FilterForm from '../components/Modules/Media/FilterForm';
import Global from '../Global';
import { useParams } from 'react-router-dom';
import MediaForm from '../components/Modules/Media/MediaForm';
import MediaTools from '../components/Modules/Media/MediaTools';
import {Row, Col} from 'react-bootstrap';
import MediaRatesImprs from '../components/Modules/Media/MediaRatesImprs';
import $ from 'jquery'

const MediasPage = () => {	

	const params = useParams()
	const [mediaType, setMediaType] = useState()

	let baseUrl = Global['apiHost'] + "/api/media/get-all"
	const [filterUrl, setFilterUrl] = useState(baseUrl)

	const handleFilterSubmit = (e) => {
		e.preventDefault()
		let data = $(e.target).serialize()
		setFilterUrl(baseUrl + '?' + data)		
	}

	const getMediaPanel = () => {
		if(params.event == 'add') {
			return (
				<Panel title="Add Media">
					<Row>
						<Col xs={7}>
							<MediaForm setMediaType={setMediaType} />
						</Col>
					</Row>					
				</Panel>
			)
		} else if(params.event == 'edit') {
			return (
				<Panel title="Edit Media">
					<Row>
						<Col xs={7}>
							<MediaForm setMediaType={setMediaType} />
						</Col>
						{mediaType == 'Television'?
							<Col>
								<MediaRatesImprs />
							</Col>
						:null}
						
					</Row>
					
				</Panel>
			)
		} else {
			return (
				<>				
					<Panel title="Filter Media">
						<FilterForm onSubmit={handleFilterSubmit} />
					</Panel>
					<Panel title="Media List" tools={<MediaTools />}>
						<DataTable 
							tableId="media-table" 
							url={filterUrl} 
							options="true"
							paging={false}
							deleteOption={false}
						/>
					</Panel>
				</>
			)
		}
	}

	return (
		<Layout>
			<Breadcrumb name="Media" />
			<Subheader title="Media" />
			{getMediaPanel()}			
		</Layout>
	);
};

export default MediasPage;
