import React from 'react';
import PropTypes from 'prop-types';
import styles from './Footer.module.scss';

const Footer = () => (
  <footer className="page-footer" role="contentinfo">
      <div className="d-flex align-items-center flex-1 text-muted">
          <span className="hidden-md-down fw-700">{new Date().getFullYear()} © FertileData 4.0 developed by PlowShare Group</span>
      </div>
  </footer>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
