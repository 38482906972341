import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Import from './Import';

const TVImport = () => {	
	return (
		<>
			<Import type="tv" />
		</>
	);
};

export default TVImport;
