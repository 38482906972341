import React from 'react';

const Subheader = (props) => (
  <div className="subheader">
      <h1 className="subheader-title">
          <i className='subheader-icon fal fa-table'></i> {props.title} 
          <small>
              
          </small>
      </h1>
  </div>
);

Subheader.propTypes = {};
Subheader.defaultProps = {};

export default Subheader;
