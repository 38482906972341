import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../../UI/Breadcrumb/Breadcrumb';
import Subheader from '../../UI/Subheader/UI/Subheader';
import Panel from '../../UI/Panel/UI/Panel';
import Master from '../../Layout/Master/Master';
import ClientDropdown from './ClientDropdown';
import Campaigns from '../Campaigns/Campaigns';
import CampaignsDropdown from '../Campaigns/CampaignsDropdown';
import CommercialsTable from '../Commercials/CommercialsTable';
import ComCatTable from '../Commercials/ComCatTable';

const Clients = () => (
  <Master>
    <Breadcrumb name="Manage Clients" />
    <Subheader title="Manage Clients" />
    <Panel title={<h1>Clients</h1>}>
      <ClientDropdown pathName="client-view" url="https://fdmvp.azrdev.psadirector.net/test/get-clients" />
    </Panel>
    <Panel title="Campaigns">
      <Campaigns />
    </Panel>
    <Panel title="Campaigns Select">
      <CampaignsDropdown />
    </Panel>
    <Panel title="Commercials Select">
      <ComCatTable />
    </Panel>
    <Panel title="Commercials Select">
      <CommercialsTable />
    </Panel>
  </Master>
);

export default Clients;
