import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';

const SelectBox = (props) => {

	/*
		props inputs
		------------
		props.name
		props.url
		props.data - json key value format
		props.label
		props.defaultValue
		props.onChange
		props.callback
	*/


	let [data, setData] = useState({})

	useEffect(() => {
		if(props.data) {
			setData(data)
		} else {
			axios.get(props.url)
				.then((res) => {					
					setData(res.data)					
					if(typeof props.callback == 'function')
						props.callback()
				})
		}		
	}, [props.url, props.data])

	const buildHtml = () => {
		var html = []
		let selected = ''
		var sortedKeys = sortObjectbyValue(data) 
		//console.log(sortedKeys);
		for(var x in sortedKeys) {	
			selected = ''		
			if(sortedKeys[x] == props.defaultValue) selected = 'selected'			
			html.push(
				<option selected={selected} value={sortedKeys[x]}>{data[sortedKeys[x]]}</option>				
			) 
		}
		
		return html 
	}

	function sortObjectbyValue(obj={},asc=false){  
		let ret = {};
		ret = Object.keys(obj).sort((a,b) => obj[a].localeCompare(obj[b]));
		return ret
	 }

	return ( 
		<>
			<Form.Label>{props.label?props.label:'Option'}</Form.Label>
			<Form.Control as="select" name={props.name} id={props.name} required={props.required} onChange={props.onChange} disabled={props.disabled} className={props.className}>
				<option value="">--Select {props.label?props.label:'Option'}--</option>
				{buildHtml()}
			</Form.Control>
		</>
	)
};

export default SelectBox;

