import React from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Button} from 'react-bootstrap';

const ResultTools = () => {
	const history = useHistory()

	const addResult = () => {
		history.push('/results/add')
	}

	return (
		<>                              
            <Button variant="primary" className="btn-sm btn-success" type="button" onClick={addResult}>Add Result</Button>            
        </>
	);
};

export default ResultTools;
