import React from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Button} from 'react-bootstrap';

const MarketTools = () => {
	const history = useHistory()

	const addMarket = () => {
		history.push('/markets/add')
	}

	return (
        <>                              
            <Button variant="primary" className="btn-sm btn-success" type="button" onClick={addMarket}>Add Market</Button>            
        </>
    )    
}

export default MarketTools;
