import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../components/UI/Breadcrumb/Breadcrumb';
import Subheader from '../components/UI/Subheader/UI/Subheader';
import Panel from '../components/UI/Panel/UI/Panel';
import Layout from '../components/Layout/Master/Master';
import {Form, Button, Row, Col} from 'react-bootstrap';
import axios from '../components/Helpers/axiosDefault';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Global from '../Global';
import ReviewRecordsTable from '../components/Modules/Import/ReviewRecordsTable';

const Import = (params) => {

	//let params = props
	let [pageTitle, setPageTitle] = useState()
	let [stage, setStage] = useState(0)
	let [progress, setProgress] = useState(0)
	let status = {}
	let statusTimeout
	let statusCheck = useRef(false);
	let [totalRecords, setTotalRecords] = useState(0)
	let [recordsRead, setRecordsRead] = useState(0)
	let [stageDesc, setStageDesc] = useState('')
	let [stationsNum, setStationsNum] = useState(0)
	let [marketsNum, setMarketsNum] = useState(0)
	let [recordsImported, setRecordsImported] = useState(0)
	let [recordsToImport, setRecordsToImport] = useState(0)
	let [csvFileName, setCSVFileName] = useState('')
	let [processTime, setProcessTime] = useState(0)
	let [timeEstimate, setTimeEstimate] = useState()
	let statusInterval;	

	const submitUpload = (e) => {
		let urlx = Global.apiHost + '/api/import/'+params.type+'/upload-sumbit'
			axios.post(urlx)
				.then((res) => {})

		let url = Global['apiHost'] + '/api/import/'+params.type+'/upload-file';
		let formData = new FormData(document.querySelector('#import-form'));
		setStage(2)
		axios.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			}
			}).then((response) => {
				//console.log(response);
			}).catch((error) => {
				// setStage(2)
				//console.log(error);
			}
		)
		e.preventDefault();
	}

	const initStatusRequest = () => {
		statusInterval = setInterval(function() {
			getUploadStatus()
		}, 1000)
	}

	const getUploadStatus = () => {
		if(window.location.pathname != Global.basename + '/import/' + params.type) {
			clearInterval(statusInterval)
		}
		let url = Global['apiHost'] + '/api/import/'+params.type+'/status';		
		axios.get(url).then((response) => {								
				if(stage != parseInt(response.data.stage)) {					
					status = response.data
					updateState()
				}			
			}).catch((error) => {
				console.log(error);
			}
		)
	}

	const updateState = () => {
		if(csvFileName != status.csv_file_name) 
			setCSVFileName(status.csv_file_name)

		if(totalRecords != status.total_records) 
			setTotalRecords(status.total_records)

		if(stage != status.stage && ((status.stage > stage && stage < 7) || stage >= 7)) 
			setStage(status.stage) 

		if(recordsRead != status.records_read) 
			setRecordsRead(status.records_read)			

		if(stageDesc != status.stage_desc) 
			setStageDesc(status.stage_desc)		
		
		if(stationsNum != status.stations)
			setStationsNum(status.stations) 

		if(marketsNum != status.markets)
			setMarketsNum(status.markets)

		if(recordsImported != status.records_imported)
			setRecordsImported(status.records_imported)
		
		if(recordsToImport != status.records_to_import)
			setRecordsToImport(status.records_to_import)

		if(processTime != status.process_time)
			setProcessTime(status.process_time)
	}

	useEffect(() => {
		if(!statusCheck.current) {
			initStatusRequest()
			statusCheck.current = true
		}
	}, [stage])

	useEffect(() => {
		if(params.type == 'tv') {
			setPageTitle('TV / Cable Import')
		} else if(params.type == 'radio') {
			setPageTitle('Radio Import')
		}
	})

	const stageZeroPanel = () => {	
		return (
			<Panel title={pageTitle}>
				<div style={{height: 150, backgroundColor: '#fafbfb', padding: 10}}>Loading...</div>
			</Panel>
		)
	}
	
	const stageOnePanel = () => {	
		return (
			<Panel title={pageTitle}>
				<Form method="post" id="import-form" style={{width: "60%"}}>
					<Row >
						<Col>                
							<Form.Group controlId="upload_file">
								<Form.Label>Select the file to import</Form.Label>
								<Form.Control type="file" name="upload_file" id="upload_file" />
							</Form.Group>
						</Col>   
					</Row>
					<br />
					<Row>
						<Col>                
							{/*<Form.Check  type="checkbox" id="skip_review" name="skip_review" label="Skip review" />*/}
						</Col>     
					</Row>
					<br />
					<Row>
						<Col>                
							<Form.Group controlId="submit">
								<Button variant="primary" type="button" onClick={submitUpload}>Submit</Button>
							</Form.Group>
						</Col>     
					</Row>
				</Form>				
			</Panel>
		)
	}

	const recordDetails = () => {
		return (
			<>
			{displayCSVFileName()}
			<Row>
				<Col>
					<div className="p-3 bg-info-200 rounded overflow-hidden position-relative text-white mb-g">
						<div>
							<h3 className="display-4 d-block l-h-n m-0 fw-500">
								{totalRecords}					
								<small className="m-0 l-h-n">Total Results Found</small>
							</h3>
						</div>
					</div>
				</Col>
				<Col>
					<div className="p-3 bg-warning-400 rounded overflow-hidden position-relative text-white mb-g">
						<div>
							<h3 className="display-4 d-block l-h-n m-0 fw-500">
								{stationsNum}					
								<small className="m-0 l-h-n">Stations</small>
							</h3>
						</div>
					</div>
				</Col>
				<Col>
					<div className="p-3 bg-primary-300 rounded overflow-hidden position-relative text-white mb-g">
						<div>
							<h3 className="display-4 d-block l-h-n m-0 fw-500">
								{marketsNum}					
								<small className="m-0 l-h-n">Markets</small>
							</h3>
						</div>
					</div>
				</Col>
			</Row>
			</>
		)
	}

	// scanning records stage
	const stageTwoPanel = () => {		
		let html = [];
				
		if(totalRecords != 0) {
			html.push(
				recordDetails()
			)
			progress = 0
		} else {
			if(progress < 100) {
				let t = setTimeout(() => {
					setProgress(progress + 4)
				}, 500)
			}
			html.push(
				<>
					<Row>
						<Col>
							<h3>Reading csv file, please wait...</h3>
						</Col>
					</Row>
				</>
			)
		}

		if(recordsRead != 0) {
			progress = 0
			html.push(
				<Row>
					<Col>
						<h3>Scanning data...</h3>
					</Col>
				</Row>
			)
			html.push(	
				<>		
					<Row>
						<Col>
							<h3>{recordsRead} / {totalRecords} Records scanned..</h3>
						</Col>
					</Row>						
				</>	
			)

			progress = Math.round((recordsRead / totalRecords) * 100)
		}

		return (
			<Panel title={pageTitle}>
				{html}
				<Row>
					<Col>
						<ProgressBar animated now={progress} />
						<br />
					</Col>
				</Row>		
				<Row>
					<Col>                
						<Form.Group>
							<Button onClick={CancelImport} variant="danger">Cancel Import</Button>
						</Form.Group>
					</Col>   
				</Row>		
			</Panel>
		)
	}

	const CompleteImport = () => {
		setStage(4)
		let url = Global['apiHost'] + '/api/import/'+params.type+'/final';
		axios.post(url, {})
	}

	const NewImport = () => {
		let url = Global.apiHost + '/api/import/'+params.type+'/new'
		setTotalRecords(0)
		setRecordsRead(0)
		setProgress(0)
		axios.post(url)
			.then((res) => {
				setStage(1)
			})
	}

	const PreviousScanImport = () => {
		let url = Global.apiHost + '/api/import/'+params.type+'/prev-scan'
		setTotalRecords(0)
		setRecordsRead(0)
		setProgress(0)
		axios.post(url)
			.then((res) => {
				setStage(3)
			})
	}

	const CancelImport = () => {
		if(confirm('Do you want to cancel the import? All the changes will be rolled back.')) {
			let url = Global.apiHost + '/api/import/'+params.type+'/cancel'
			axios.post(url)
				.then((res) => {
					setStage(1)
					setProgress(0)
					setTotalRecords(0)
					setRecordsRead(0)
					alert('Import is canceled.')
				})
		}
	}

	// review records stage
	const stageThreePanel = () => {			
		return (
			<Panel title={pageTitle}>
				<Row>
					<Col>
						<h3 id="test-id">{stageDesc}</h3>
					</Col>
				</Row>	
				{recordDetails()}
				<Row style={{width: "520px"}}>
					{stage==3?
						<Col>                
							<Form.Group>
								<Button onClick={CompleteImport} style={{width: "250px"}} variant="primary" type="button">Proceed Final Import</Button>
							</Form.Group>
						</Col>  
					:''}					   
					<Col>                
						<Form.Group>
							<Button onClick={CancelImport} variant="danger">Cancel Import</Button>
						</Form.Group>
					</Col>   
				</Row>
				<br />
				{stage==3?
				<Row>
					<Col>
						<ReviewRecordsTable stage={stage} type={params.type} />
					</Col>
				</Row>	
				:''}	
			</Panel>
		)
	}

	// Queue stage
	const stageFourPanel = () => {
		return (
			<Panel title={pageTitle}>
				<Row>
					<Col>
						<h3 id="test-id">Import job is added to the queue and will be processed soon.</h3>
					</Col>
				</Row>
				{recordDetails()}				
				<Row>
					<Col>                
						<Form.Group>
							<Button onClick={CancelImport} variant="danger">Cancel Import</Button>
						</Form.Group>
					</Col>   
				</Row>				
			</Panel>
		)		
	}

	// Import running stage
	const stageFivePanel = () => {
		progress = Math.round((recordsImported / recordsToImport) * 100)
		var estimatedTime = ((recordsToImport - recordsImported) * processTime) / 1000
		estimatedTime = new Date(estimatedTime * 1000).toISOString().substr(11, 8)
		estimatedTime = estimatedTime.split(':');
		var hours = parseInt(estimatedTime[0])
		var mins = parseInt(estimatedTime[1])
		var secs = parseInt(estimatedTime[2])

		if(recordsImported < 110) {
			estimatedTime = ' Calculating... '
		} else if(hours != 0) {
			if(hours == 1) {
				estimatedTime = ' Approximately ' + parseInt(estimatedTime[0]) + ' hour'
			} else {
				estimatedTime = ' Approximately ' + parseInt(estimatedTime[0]) + ' hours'
			}			
		} else if(mins != 0) {
			if(mins == 1) {
				estimatedTime = parseInt(estimatedTime[1]) + ' minute'
			} else {
				estimatedTime = parseInt(estimatedTime[1]) + ' minutes'
			}
		} else if(secs != 0) {
			if(secs == 1) {
				estimatedTime = parseInt(estimatedTime[2]) + ' second'
			} else {
				estimatedTime = parseInt(estimatedTime[2]) + ' seconds'
			}
		} else {
			estimatedTime = ' Completing now'
		}
		return (
			<Panel title={pageTitle}>
				<Row>
					<Col>
						<h3 id="test-id">Importing Results</h3>
					</Col>
				</Row>
				{recordDetails()}
				<Row>
					<Col>
						<h3>{recordsImported} / {recordsToImport} Records Imported..</h3>
					</Col>
				</Row>
				<Row>
					<Col>
						<ProgressBar animated now={progress} />
						<div style={{marginTop: 5}}><i>Estimated time remaining: {estimatedTime}</i></div>
						<br />						
					</Col>
				</Row>		
				<Row>
					<Col>                
						<Form.Group>
							<Button onClick={CancelImport} variant="danger">Cancel Import</Button>
						</Form.Group>
					</Col>   
				</Row>				
			</Panel>
		)		
	}

	// Completion stage
	const stageSevenPanel = () => {
		return (
			<Panel title={pageTitle}>
				<Row>
					<Col>
						<h3 id="test-id">Importing Results</h3>
					</Col>
				</Row>
				{recordDetails()}
				<Row>
					<Col>
						<h3>All records have been successfully imported. </h3>
					</Col>
				</Row>		
				<Row>
					<Col>                
						<Form.Group>
							{/* <Button onClick={CancelImport} variant="danger">Rollback previous import </Button>
							&nbsp; &nbsp; */}
							<Button onClick={NewImport} variant="success">New Import</Button>
							{/* &nbsp; &nbsp;
							<Button onClick={PreviousScanImport} variant="success">Import from previous scan</Button> */}
						</Form.Group>
					</Col>     
				</Row>				
			</Panel>
		)		
	}

	// Error Stage
	const stageElevenPanel = () => {
		return (
			<Panel title={pageTitle}>
				<Row>
					<Col>
						<div class="alert alert-danger" role="alert">
							{stageDesc}
						</div>
					</Col>
				</Row>		
				<Row>
					<Col>                
						<Form.Group>
							<Button onClick={NewImport} variant="success">Try upload again</Button>
						</Form.Group>
					</Col>     
				</Row>				
			</Panel>
		)
	}

	const displayCSVFileName = () => {
		let html = [];
		if(csvFileName != '') {
			html.push(<>
				<div style={{padding:"10px 0", fontSize: 18}}>Importing CSV File <b>"{csvFileName}"</b></div>
			</>)
		}
		return (
			<>
				{html}
			</>
		)
	}

	const getCurrentPanel = () => {
		if(stage == 0) {
			return stageZeroPanel()
		} else if(stage == 1) {
			return stageOnePanel()
		} else if(stage == 2) {
			return stageTwoPanel()
		} else if(stage == 3) {
			return stageThreePanel()
		} else if(stage == 4) {
			return stageFourPanel()
		} else if(stage == 5 || stage == 6) {
			return stageFivePanel()
		} else if(stage == 7) {
			return stageSevenPanel()
		} else if(stage == 11) {
			return stageElevenPanel()
		}
	}

	return (
		<Layout>
			<Breadcrumb name={pageTitle} />
			<Subheader title={pageTitle} />
			{getCurrentPanel()}
		</Layout>					
	);
};

export default Import;
