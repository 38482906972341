import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {Form, Button, Row, Col} from 'react-bootstrap';
import SubmitButton from '../../UI/Form/SubmitButton';
import SelectBox from '../../UI/Form/SelectBox';
import axios from '../../Helpers/axiosDefault';
import Global from '../../../Global';
//import MyMap from '../../UI/Highcharts/Map';
import USMap from '../../UI/Highcharts/USMap';
import Validate from '../../UI/Form/Validate/Validate';

const MarketForm = () => {

	const params = useParams()
	const history = useHistory()
	const [marketValues, setMarketValues] = useState({})

	const SubmitAction = (e) => {
		e.preventDefault()
		if(!Validate.checkForm()) return false
		
		let formData = new FormData(document.querySelector('#markets-form'))
		let url = Global['apiHost'] + '/api/market/save'
		axios.post(url, formData)
			.then(res => {
				history.push('/markets')
			})
		
		return false
	}

	const mapClick = (values) => {
		document.querySelector(['input[name=x']).value = values.x
		document.querySelector(['input[name=y']).value = values.y
	}

	useEffect(() => {
		if(params.market_id) {
			let url = Global['apiHost'] + '/api/market/get/' + params.market_id
			axios.get(url)
				.then(res => {
					setMarketValues(res.data)					
				})
			}
	}, params.market_id)

	return (
		<>
		<Form onSubmit={SubmitAction} style={{width: "60%"}} id="markets-form">
			{params.market_id?
				<Form.Control type="hidden" name="id" defaultValue={params.market_id} />
			:''}
			<Row>
				<Col>                
					<Form.Group controlId="media">
						<Form.Label>Market Name</Form.Label>
						<Form.Control type="text" name="market" placeholder="Market Name" defaultValue={marketValues.market} className="required" />
					</Form.Group>
				</Col>
				<Col xs={3}>                
					<Form.Group controlId="market_fm_id">
						<Form.Label>Market ID</Form.Label>
						<Form.Control type="text" readOnly name="market_fm_id" placeholder="Market ID" defaultValue={marketValues.market_fm_id} />
					</Form.Group>
				</Col>
			</Row>
			<br />
			<Row>
				<Col>                
					<Form.Group controlId="MediaType">
						<Form.Label>Media Type</Form.Label>
						<Form.Control name="MediaType" as="select" key={marketValues.MediaType} defaultValue={marketValues.MediaType} className="required">
							<option value="">-- Select Category --</option>
							<option value="TV">Television</option>
							<option value="Cable">Cable</option>
							<option value="Radio">Radio</option>
							<option value="Newspaper">Newspaper</option>
							<option value="Magazine">Magazine</option>
							<option value="OOH">OOH</option>
						</Form.Control>
					</Form.Group>
				</Col>
				<Col>                
					<Form.Group controlId="rank">
						<Form.Label>Rank</Form.Label>
						<Form.Control type="text" name="rank" placeholder="" defaultValue={marketValues.rank} />
					</Form.Group>
				</Col>
				<Col>     
					<SelectBox url={Global['apiHost'] + '/api/form/states'} label="State" name="state" defaultValue={marketValues.state} />           
				</Col>
			</Row>
			<br />
			<Row>
				<Col xs={3}>
					<Form.Group controlId="population_12p">
						<Form.Label>Population 12+</Form.Label>
						<Form.Control type="text" name="population_12p" placeholder="" defaultValue={marketValues.population_12p} />
					</Form.Group>
				</Col>
				<Col xs={2}>
					<Form.Group controlId="x">
						<Form.Label>Map X</Form.Label>
						<Form.Control type="text" name="x" placeholder="" readOnly defaultValue={marketValues.x} />
					</Form.Group>
				</Col>
				<Col xs={2}>
					<Form.Group controlId="y">
						<Form.Label>Map Y</Form.Label>
						<Form.Control type="text" name="y" placeholder="" readOnly defaultValue={marketValues.y} />
					</Form.Group>
				</Col>
			</Row>
			<br />		
			<SubmitButton />			
		</Form>
		<Row>
			<Col style={{height: 700}}>
				<USMap handleMapClick={mapClick} points={{x:parseInt(marketValues.x), y: parseInt(marketValues.y)}} name={marketValues.market} key={marketValues.x} />
			</Col>
		</Row>
		</>
	)
}

export default MarketForm;
