import React, { useState, useEffect } from 'react';
//import mapData from "./MapData";
import Highcharts from "highcharts";
import axios from 'axios';
import './styles.css';
//import { parseWithOptions } from 'date-fns/fp';
import Global from '../../../Global';

require("highcharts/modules/map")(Highcharts);

const USMap = (props) => {
		
	const [points, setPoints] = useState(props.points)
	
	let defaultOptions = {
		title: {
		  text: "Widget click by location",
		  style: {
			color: "#fff"
		  }
		},
		chart: {
		  backgroundColor: "transparent",        
		  type: "map",
		  map: null,
		  height: 550,
		  width: 1000,
		  events: {
			  click: function (e) {
				var x = Math.round(e.xAxis[0].value), 
				y = Math.round(e.yAxis[0].value)
				mapChart.series[1].setData([])
				mapChart.series[1].addPoint({ x: x, y: y })
				props.handleMapClick({ x: x, y: y })
			  }
		  }
		},
		mapNavigation: {
		  enabled: true,
		  enableButtons: false
		},
		credits: {
		  enabled: false
		},
		plotOptions: {
			  map: {
				  borderColor: '#B6BB69',
				  className: 'cbgcolor',
				  zIndex: 500
			  },
			  series: {
				zIndex: 200
			  }
		  },
		legend: {
		  align: "right",
		  verticalAlign: "top",
		  x: -100,
		  y: 70,
		  floating: true,
		  enabled: false,
		  layout: "vertical",
		  valueDecimals: 0,
		  backgroundColor: 'rgba(255,255,255,0.85)',
		},
		series: [
		  {
			name: "",
			type: 'map',
			className: 'cbgcolor',
			dataLabels: {
			  enabled: true,
			  color:'#000',
			  shadow: false,
			  format: "{point.postal-code}",
			  style: {
				textTransform: "uppercase",
				fontWeight: 'normal',
				boxShadow: 0
			  }
			},
			tooltip: {
			  pointFormat: '{point.name}',
			  headerFormat: '',
			  formatter: false,
			  backgroundColor: 'none',
			  borderWidth: 'none',
			  style: {
					  color: '#FFF',
					  fontSize: '12px',
					  padding: '8px',
					  visibility: 'false'
			  }
			},
			cursor: "pointer",
			joinBy: "postal-code",
			data: [],
			showInLegend: false
		  },
		  {
			  type: "mappoint",
			  data: [],
			  color: 'red'
		  }
		]
	}	

	let mapChart = null;
	const loadMap = (options, points) => {
		axios.get(Global.mapDataUrl)
			.then(res => {
				let stateData = JSON.parse(res.data.substring(41, res.data.length - 1))				
				options.series[0].data = []
				options.series[0].mapData = stateData
				if(points != undefined) {
					options.series[1].data = [points]
					options.series[1].name = props.name
				}
				mapChart = Highcharts.mapChart('map-container', options)
			})
	}

	useEffect(() => {
		if(!isNaN(points.x) && !isNaN(points.y) && points.x != 999999) {
			loadMap(defaultOptions, points)
		} else {
			loadMap(defaultOptions)
		}		
	})

	return (
		<>
			<div id="map-container">USMap Component</div>
		</>
	);
};

export default USMap;
