import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom'

const RedirectHome = () => {
	const history = useHistory()
	
	useEffect(() => {
		history.push('/clients')
	})

	return (
		<></>
	)
}

export default RedirectHome;
