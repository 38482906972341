import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import 'react-select2-wrapper/css/select2.css';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';
import {useHistory, useParams} from 'react-router-dom';
import Global from '../../../Global'
import axios from '../../Helpers/axiosDefault';
require('react-select2');

const ClientDropdown = (props) => {

    const history = useHistory();
    const params = useParams();

    const fetchClients = () => {
        axios.get(Global.apiHost + '/api/form/select2/clients')
        .then(
            (result) => {
                $('#clientNames').select2({
                    data: result.data.results
                });
                $('#clientNames').on('select2:select', function() {
                    if(props.pathName) {
                        history.push('/'+props.pathName+'/' + $(this).val());
                     } else {
                        history.push('/clients/' + $(this).val());
                    }
                })
                if(params.client_id)
                    $('#clientNames').val(params.client_id).trigger('change');
            }
        )
    }

    useEffect(() => {
        if(!$('#clientNames').hasClass('select2-hidden-accessible')) {
            fetchClients();
        }   
        if(params.client_id != $('#clientNames').val())   
            $('#clientNames').val(params.client_id).trigger('change');  
    }, [params.client_id]);

    return (
        <select style={{width: "100%"}} id="clientNames"></select>
    )
}

ClientDropdown.propTypes = {};  
ClientDropdown.defaultProps = {};

export default ClientDropdown;