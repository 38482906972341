import React, { useState, useEffect } from 'react';
import '../../../common/css/datagrid/datatables/datatables.bundle.css';
import {Tabs, Tab} from 'react-bootstrap'
import {Form, Button, Row, Col} from 'react-bootstrap';
import Global from '../../../Global';
import axios from '../../Helpers/axiosDefault';
import './ReviewRecordsTable.css'

const $ = require('jquery');
require("datatables.net-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis.js");
require("datatables.net-buttons/js/buttons.flash.js");
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");
require("datatables.net-colreorder-bs4");
require("datatables.net-responsive-bs4");
require("datatables.net-select-bs4");

const ReviewRecordsTable = (props) => {

	const baseUrl = Global['apiHost'] + '/api/import/'+props.type+'/review-records'
	const [filterUrl, setFilterUrl] = useState(baseUrl)

	const fetchData = () => {
		let url = filterUrl
		axios.get(url)
		.then((response) => {//days-weeks
			$('#results-table').DataTable({
                data: response.data.data,
                columns: response.data.dataColumnsFormated,
                destroy: true
            });

			$('#days-weeks').DataTable({
                data: response.data.daysData.data,
                columns: response.data.daysData.columns,
                destroy: true
            });

			let columns = response.data.stationColumnsFormated
			let newColumnIndex = null
			let mediaTypeIndex = null
			let callSignIndex = null
			let importIndex = null
			let updateIndex = null
			for(let x in columns) {
				if(columns[x].title.indexOf('Import?') != -1) {
					importIndex = x
					columns[x].title = '<input type="checkbox" id="import_toggle_all" checked /> Import?'
					columns[x].orderable = false
				}

				if(columns[x].title.indexOf('Update?') != -1) {
					updateIndex = x
					columns[x].title = '<input type="checkbox" id="update_toggle_all" checked /> Update?'
					columns[x].orderable = false
				}

				if(columns[x].title == 'Media Type') {
					mediaTypeIndex = x
				}

				if(columns[x].title == 'Call Sign') {
					callSignIndex = x
				}

				if(columns[x].title == 'New') {
					columns[x]['visible'] = false
					newColumnIndex = x
				}
			}
						
			let data = response.data.stations
			let table = $('#stations-table').DataTable({
                data: data,
                columns: columns,
                destroy: true,
				pageLength: 100,
				order: [],
				drawCallback: function(settings, json) {
					$('.toggle_import, .toggle_update').on('click', function() {
						let url = Global['apiHost'] + '/api/import/'+props.type+'/toggle-import';
						let value = 0
						if($(this).attr('class') == 'toggle_update') {
							url = Global['apiHost'] + '/api/import/'+props.type+'/toggle-update';
						}				
						
						if($(this).prop('checked')) {
							value = 1
						}
						axios.post(url, {
							's': $(this).attr('data-id'),
							'v': value
						})
					})

					$('#import_toggle_all').on('click', function() {
						var rows = table.rows({ 'search': 'applied' }).nodes();
						$('.toggle_import', rows).prop('checked', this.checked);	
						let value = 1
						if(!this.checked) value = 0
						
						let url = Global['apiHost'] + '/api/import/'+props.type+'/toggle-import';
						axios.post(url, {
							'ttype': 'batch',
							'v': value,
							'f': $('select[name=import-filters]').val()
						})
					})

					$('#update_toggle_all').on('click', function() {
						var rows = table.rows({ 'search': 'applied' }).nodes();
						$('.toggle_update', rows).prop('checked', this.checked);	

						let value = 1
						if(!this.checked) value = 0
						let url = Global['apiHost'] + '/api/import/'+props.type+'/toggle-update';
						axios.post(url, {
							'ttype': 'batch',
							'v': value,
							'f': $('select[name=import-filters]').val()
						})				
					})
				},
				createdRow: function (row, data, dataIndex) {
					if(newColumnIndex != null) {
						if(data[newColumnIndex] == 1) {
							$(row).addClass('new')
						}
					}

					if(importIndex != null) {
						var checked = 'checked';
						if($(row).children('td:eq('+importIndex+')').html() == 0 || $('#import_toggle_all').prop('checked') == false) {
							checked = 0
						}		
						$(row).children('td:eq('+importIndex+')').html('<input type="checkbox" class="toggle_import" '+checked+' data-id="'+$(row).children('td:eq(0)').html()+'" />');	 				
					}

					if(updateIndex != null) {
						var checked = 'checked';
						if($(row).children('td:eq('+updateIndex+')').html() == 0 || $('#update_toggle_all').prop('checked') == false) {
							checked = 0
						}		
						$(row).children('td:eq('+updateIndex+')').html('<input type="checkbox" class="toggle_update" '+checked+' data-id="'+$(row).children('td:eq(0)').html()+'" />');	 				
					}
					
					$(row).children('td:eq('+mediaTypeIndex+')').on('dblclick', function() {
						if(!$(this).parent().hasClass('new')) {
							if($('#change_media_type').length == 0) {
								alert('You cannot change the media type of existing station during import. Please go to Edit Media to change media type.');
							}
							resetMediaTypeDropdown()
							return false;
						}
						var toggle = false
						if($(this).find('#change_media_type').length != 0) {
							toggle = true
						}
						if($('#change_media_type').length != 0) {
							resetMediaTypeDropdown()
						}
						if(toggle) return

						var value = $(this).html()						
						var html = '<select id="change_media_type">'
									+ '<option value="Television">Television</option>'
									+ '<option value="NationalCable">NationalCable</option>'
									+ '<option value="LocalCable">LocalCable</option>'
									+ '<option value="LocalCable">RegionalCable</option>'
									+ '</select>';
						$(this).html(html)
						$('#change_media_type').val(value)
						$('#change_media_type').on('change', function() {
							var url = Global.apiHost + '/api/import/'+props.type+'/update_mediatype'
							$.post(url, {mt: $(this).val(), cs: data[callSignIndex]}, function(res) {
								resetMediaTypeDropdown()
							})
						})
					})
				}
            });
		})
	}

	const resetMediaTypeDropdown = () => {
		$('#change_media_type').parent().html($('#change_media_type').val())
		$('#change_media_type').remove()
	}
		
	useEffect(() => {		
		fetchData()				
	}, [props.stage, filterUrl])

	const onChangeFilter = (e) => {
		let url = baseUrl + '?filter=' + e.target.value
		setFilterUrl(url)
		//fetchData()		
	}

	const getFilterOptions = () => {
		var html = []
		if(props.type == 'tv') {
			html.push(
				<div style={{width:200, position: 'absolute', left: 250, top: 50, zIndex: 2000}}>
					<Form.Control name="import-filters" as="select" onChange={onChangeFilter}>
						<option value="">--All--</option>
						<option value="New">New media</option>
						<option value="Television">Television</option>
						<option value="NationalCable">NationalCable</option>
						<option value="LocalCable">LocalCable</option>
					</Form.Control>
				</div>
			)
		} else if(props.type == 'radio') {
			html.push(
				<div style={{width:200, position: 'absolute', left: 250, top: 50, zIndex: 2000}}>
					<Form.Control name="import-filters" as="select" onChange={onChangeFilter}>
						<option value="">--All--</option>
						<option value="New">New media</option>
					</Form.Control>
				</div>
			)
		}
		return html
	}
	
	return (
		<>
			<Tabs id="review-records">         
				<Tab eventKey="stations" title="Review Stations">
					<br />
					{getFilterOptions()}		
					<table className="table table-bordered table-hover table-striped w-100" id="stations-table" ></table>
				</Tab>
				<Tab eventKey="results" title="Review Results">
					<br />
					<table className="table table-bordered table-hover table-striped w-100" id="results-table" ></table>
				</Tab>
				<Tab eventKey="days-weeks" title="Days Covered">
					<br />
					<Row>
						<Col xs={5}>
							<table className="table table-bordered table-hover table-striped w-100" id="days-weeks" ></table>
						</Col>
					</Row>
					
				</Tab>
			</Tabs>
		</>
	);
};

export default ReviewRecordsTable;
