import React from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Button} from 'react-bootstrap';

const MediaTools = () => {

	const history = useHistory()

	const addMedia = () => {
		history.push('/media/add')
	}

	return (
        <>                              
            <Button variant="primary" className="btn-sm btn-success" type="button" onClick={addMedia}>Add Media</Button>            
        </>
    )    
}

export default MediaTools;
