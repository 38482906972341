import React, { useState, useEffect } from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Form, Button, Row, Col} from 'react-bootstrap';
import SubmitButton from '../../UI/Form/SubmitButton';
import Global from '../../../Global';
import axios from '../../Helpers/axiosDefault';
import SelectBox from '../../UI/Form/SelectBox';
import Validate from '../../UI/Form/Validate/Validate';

const MediaForm = (props) => {

	const params = useParams()
	const [MediaType, setMediaType] = useState('')
	const history = useHistory()
	let isElementInSecondRow = false

	let [mediaValues, setMediaValues] = useState({})

	const SubmitAction = (e) => {
		e.preventDefault()
		if(!Validate.checkForm()) return false

		let formData = new FormData(document.querySelector('#media-form'))
		let url = Global['apiHost'] + '/api/media/save'
		axios.post(url, formData)
			.then(res => {
				history.push('/media')
			})		
		return false
	}

	const onMediaTypeChange = (e) => {
		isElementInSecondRow = false
		setMediaType(e.target.value)	
		props.setMediaType(e.target.value)	
	}

	const getCallSign = () => {
		let allow = ['Television', 'LocalCable', 'NationalCable', 'Radio']
		if(allow.includes(MediaType)) {
			return (
				<Col> 
					<Form.Group controlId="call_sign">
						<Form.Label>Call Sign</Form.Label>
						<Form.Control type="text" name="call_sign" placeholder="Call Sign" defaultValue={mediaValues.call_sign} className="required" />
					</Form.Group>
				</Col>
			)
		}
	}

	const getMarket = () => {
		let allow = ['Television', 'LocalCable', 'Radio']
		if(allow.includes(MediaType)) {
			isElementInSecondRow = true
			return (
				<Col>    
					<SelectBox url={Global['apiHost'] + '/api/form/markets/by-type/' + MediaType} label="Market" name="market_id" defaultValue={mediaValues.market_id} className="required" />            
				</Col>
			)			
		}
	}

	const getAffiliate = () => {
		let allow = ['Television']
		if(allow.includes(MediaType)) {
			isElementInSecondRow = true
			return (
				<Col> 
					<Form.Group controlId="affiliate">
						<Form.Label>Affiliate</Form.Label>
						<Form.Control type="text" name="affiliate" placeholder="Affiliate" defaultValue={mediaValues.affiliate} />
					</Form.Group>
				</Col>
			)
		}
	}

	const getAmFM = () => {
		let allow = ['Radio']
		if(allow.includes(MediaType)) {
			isElementInSecondRow = true
			return (
				<Col> 
					<Form.Group controlId="am_fm">
						<Form.Label>AM/FM</Form.Label>
						<Form.Control name="am_fm" as="select" key={mediaValues.am_fm} defaultValue={mediaValues.am_fm}>
							<option value="">-- Select AM/FM --</option>	
							<option value="AM">AM</option>	.
							<option value="FM">FM</option>				
						</Form.Control>
					</Form.Group>
				</Col>
			)
		}
	}

	const getSecondRowElements = () => {
		let market = getMarket();
		let affiliate = getAffiliate();
		let amfm = getAmFM();
		if(isElementInSecondRow) {
			return (
				<>
					<Row>
						{market}
						{affiliate}
						{amfm}
					</Row>
					<br />
				</>
			)
		}
	}

	useEffect(() => {
		if(params.media_id) {
			axios.get(Global['apiHost'] + '/api/media/get/' + params.media_id)
			.then(response => {
				setMediaValues(response.data)
				setMediaType(response.data['media_type'])	
				props.setMediaType(response.data['media_type'])				
			})
		}
	}, [params.media_id])

	return (
		<Form onSubmit={SubmitAction} style={{width: "100%"}} id="media-form">
			{params.media_id?
				<Form.Control type="hidden" name="id" defaultValue={mediaValues.id} />
			:''}
			<Row>
				<Col>                
					<Form.Group controlId="media">
						<Form.Label>Media Name</Form.Label>
						<Form.Control type="text" name="media" placeholder="Media Name" defaultValue={mediaValues.media} className="required" />
					</Form.Group>
				</Col>
				<Col>                
					<Form.Group controlId="media_type">
						<Form.Label>Media Type</Form.Label>
						<Form.Control name="media_type" as="select" onChange={onMediaTypeChange} key={mediaValues.media_type} defaultValue={mediaValues.media_type} className="required">
							<option value="">-- Select Category --</option>
							<option value="Television">Television</option>
							<option value="LocalCable">Local Cable</option>
							<option value="NationalCable">National Cable</option>
							<option value="Radio">Radio</option>
							<option value="Newspaper">Newspaper</option>
							<option value="Magazine">Magazine</option>
							<option value="Ooh">OOH</option>
						</Form.Control>
					</Form.Group>
				</Col>
				{getCallSign()}
			</Row>
			<br />
			{getSecondRowElements()}
			{/* <Row>
				<Col xs={3}>                
					<Form.Group controlId="media_fm_id">
						<Form.Label>Media ID</Form.Label>
						<Form.Control type="text" name="media_fm_id" readOnly placeholder="" defaultValue={mediaValues.media_fm_id} className="required" />
					</Form.Group>
				</Col>
			</Row> 
			<br />*/}
			<Row>
				<Col>                
					<Form.Group controlId="address">
						<Form.Label>Address</Form.Label>
						<Form.Control autoComplete={false} type="text" name="address" placeholder="" defaultValue={mediaValues.address} />
					</Form.Group>
				</Col>
			</Row>
			<br />
			<Row>
				<Col>                
					<Form.Group controlId="city">
						<Form.Label>City</Form.Label>
						<Form.Control autoComplete={false} type="text" name="city" placeholder="" defaultValue={mediaValues.city} />
					</Form.Group>
				</Col>
				<Col>                
					<Form.Group controlId="state">
						<Form.Label>State</Form.Label>
						<Form.Control type="text" name="state" placeholder="" defaultValue={mediaValues.state} />
					</Form.Group>
				</Col>
				<Col>                
					<Form.Group controlId="zip">
						<Form.Label>Zip</Form.Label>
						<Form.Control autoComplete={false} type="text" name="zip" placeholder="" defaultValue={mediaValues.zip} />
					</Form.Group>
				</Col>
			</Row>
			<br />
			<Row>
				<Col>                
					<Form.Group controlId="primary_contact_name_first">
						<Form.Label>Primary Contact Name</Form.Label>
						<Form.Control autoComplete={false} type="text" name="primary_contact_name_first" placeholder="" defaultValue={mediaValues.primary_contact_name_first} />
					</Form.Group>
				</Col>
				<Col>                
					<Form.Group controlId="primary_contact_title">
						<Form.Label>Primary Contact Title</Form.Label>
						<Form.Control autoComplete={false} type="text" name="primary_contact_title" placeholder="" defaultValue={mediaValues.primary_contact_title} />
					</Form.Group>
				</Col>
				<Col>                
					<Form.Group controlId="primary_contact_email">
						<Form.Label>Primary Contact Email</Form.Label>
						<Form.Control autoComplete={false} type="text" name="primary_contact_email" placeholder="" defaultValue={mediaValues.primary_contact_email} />
					</Form.Group>
				</Col>
			</Row>
			<br />
			<Row>
				<Col>                
					<Form.Group controlId="primary_contact_email2">
						<Form.Label>Primary Email 2</Form.Label>
						<Form.Control autoComplete={false} type="text" name="primary_contact_email2" placeholder="" defaultValue={mediaValues.primary_contact_email2} />
					</Form.Group>
				</Col>
				<Col>                
					<Form.Group controlId="primary_contact_email3">
						<Form.Label>Primary Email 3</Form.Label>
						<Form.Control autoComplete={false} type="text" name="primary_contact_email3" placeholder="" defaultValue={mediaValues.primary_contact_email3} />
					</Form.Group>
				</Col>
				<Col>                
					<Form.Group controlId="primary_contact_phone">
						<Form.Label>Primary Phone</Form.Label>
						<Form.Control type="text" name="primary_contact_phone" placeholder="" defaultValue={mediaValues.primary_phone} />
					</Form.Group>
				</Col>
			</Row>
			<br />
			<SubmitButton />
		</Form>
	);
};


export default MediaForm;
