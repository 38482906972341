/* global jQuery, $, bootbox */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Master.module.scss';

// CSS Fi;es
import '../../../common/css/vendors.bundle.css';
import '../../../common/css/app.bundle.css';
import '../../../common/css/themes/cust-theme-7.css';
import '../../../common/css/skins/skin-master.css';
import '../../../common/css/fd-custom.css';

import Sidebar from '../Sidebar/Sidebar.js';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';
import Breadcrumb from '../../UI/Breadcrumb/Breadcrumb';
import Subheader from '../../UI/Subheader/UI/Subheader';
import Panel from '../../UI/Panel/UI/Panel';
import DataTable from '../../UI/DataTable/DataTable';

const Master = (props) => ( 
  <div className="page-wrapper">
    <div className="page-inner"> 
      <Sidebar />
      <div class="page-content-wrapper">
        <Header clientName="Test" />
        <main id="js-page-content" role="main" className="page-content">
          {props.children}         
        </main>
        <div class="page-content-overlay" data-action="toggle" data-class="mobile-nav-on"></div>
        <Footer />
      </div>
    </div>
  </div>
);

// JS Files
//require('../../../common/js/vendors.bundle.js');
//require('../../../common/js/app.bundle.js');
//require('../../../common/js/custom.js');
//require ('../../../common/js/datagrid/datatables/datatables.bundle.js')

Master.propTypes = {};
Master.defaultProps = {};
export default Master;
