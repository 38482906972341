import React, { useState, useEffect } from 'react';
import {Form, Button, Row, Col, Modal} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../components/UI/Breadcrumb/Breadcrumb';
import Subheader from '../components/UI/Subheader/UI/Subheader';
import Panel from '../components/UI/Panel/UI/Panel';
import Layout from '../components/Layout/Master/Master';
import Global from '../Global';
import DataTable from '../components/UI/DataTable/DataTable';
import ResultForm from '../components/Modules/Results/ResultForm';
import FilterForm from '../components/Modules/TestResults/FilterForm'
import axios from '../components/Helpers/axiosDefault';
import TestTools from '../components/Modules/TestResults/TestTools';
import '../components/Modules/TestResults/styles.css'
const $ = require('jquery');

const TestResults = () => {

	const params = useParams()
	let resultsNewUrl = Global['apiHost'] + "/api/test/import-results-new"
	let resultsOldUrl = 'https://fd4.fertiledata.com/api/test/import-results-old'
	const [filterUrl, setFilterUrl] = useState('')
	const [resultsNewData, setResultsNewData] = useState({})
	const [resultsOldData, setResultsOldData] = useState([])
	const [showModal, setShowModal] = useState(false);
	const [resultsCommercialDataNew, setResultsCommercialDataNew] = useState([])
	const [resultsCommercialDataOld, setResultsCommercialDataOld] = useState([])

	const handleFilterSubmit = (e) => {
		e.preventDefault()		
		let formData = new FormData(e.target)

		axios.post(resultsNewUrl, formData)
			.then(res => {
				setResultsNewData(res.data)
			})		

		axios.post(resultsOldUrl, formData)
			.then(resold => {
				setResultsOldData(resold.data)
			})
	}

	const createdRowFunc = (row, data, dataIndex) => {		
		row.querySelector('td').style.cursor = 'pointer'		
		row.querySelector('td').onclick = function(e) {
			let formData = new FormData(document.querySelector('#results-filter'))
			console.log(this.innerHTML)
			formData.append('commercial_id', e.target.innerHTML)
			axios.post( Global['apiHost'] + "/api/test/commercials/new", formData)
				.then(res => {
					setResultsCommercialDataNew(res.data)
				})				
			axios.post( "https://fd4web.azrtest.psadirector.net/api/test/commercials/old", formData)
				.then(res => {
					setResultsCommercialDataOld(res.data)
				})
			setShowModal(true)
		}
	}

	const showMismatchValues = () => {
		
	}

	const getResultsHtml = (results, attachEvents = false) => {
		let html = [];
		let searchOption = false		
		for(let key in results) {
			searchOption = key == 'Totals_By_Commercials' || key == 'Totals_By_Media'?true:false
			let callback = null
			let createdRow = null
			if(key == 'Totals_By_Commercials' && attachEvents) {
				//callback = createdRow
				createdRow = createdRowFunc
			} else {
				createdRow = null
			}
			html.push(
				<>
					<Panel title={key.replace(/_/g, ' ')}>
						<DataTable 
							tableId={"results-" + key + Math.floor((Math.random() * 100) + 1)}  
							result={results[key]} 
							options={false}
							editOption={false}
							deleteOption={false}
							searchOption={searchOption}
							createdRow={createdRow}
							callback={callback}
						/>
					</Panel>
				</>
			)
		}
		return html
	}

	const downloadHandler = () => {
		var params = $('#results-filter').serialize();
		var url = 'https://fd4web.azrtest.psadirector.net/api/test/download?' + params;
		window.open(url)
	}
	

	const getResultsPanel = () => {
		return (
			<>				
				<Panel title="Filter Results" tools={<TestTools downloadHandler={downloadHandler} />}>
					<FilterForm onSubmit={handleFilterSubmit} />
				</Panel>
				<Row>
					<Col xs="6">	
						<div className="alert bg-info-500">
							<h1 style={{marginBottom: 0}}>Results from FD4 TEST DB</h1>
						</div>												
						{getResultsHtml(resultsNewData, true)}
					</Col>
					<Col xs="6">
						<div className="alert bg-danger-400 text-white">
							<h1 style={{marginBottom: 0}}>Results from LIVE DB</h1>
						</div>	
						{getResultsHtml(resultsOldData)}
					</Col>
				</Row>
			</>
		)
	}

	return (
		<Layout>
			<Breadcrumb name="TEST Import Results" />
			<Subheader title="TEST Import Results" />
			{getResultsPanel()}
				<Modal
					show={showModal}
					onHide={() => setShowModal(false)}
					style={{maxWidth:'100%'}}
					dialogClassName="modal-90w"
					aria-labelledby="example-custom-modal-styling-title"
				>
					<Modal.Header closeButton>
					<Modal.Title id="example-custom-modal-styling-title">
						Values by Commercials
					</Modal.Title>
					</Modal.Header>
					<Modal.Body>
					<Row>
						<Col>
							{resultsCommercialDataNew.length != 0?
								<DataTable 
									tableId="result-comercials-new"  
									result={resultsCommercialDataNew} 
									options={false}
									editOption={false}
									deleteOption={false}
									searchOption={true}
								/>:''
							}
							
						</Col>
						<Col>
						{resultsCommercialDataOld.length != 0?
							<DataTable 
								tableId="result-comercials-old"  
								result={resultsCommercialDataOld}
								options={false}
								editOption={false}
								deleteOption={false}
								searchOption={true}
							/>:''
						}
						</Col>
					</Row>
					</Modal.Body>
				</Modal>			
		</Layout>
	);
};

export default TestResults;
