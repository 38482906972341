import React, { useState, useContext } from 'react';
import AuthContext from './AuthContext';
import axiosDefault from '../components/Helpers/axiosDefault';
import Global from '../Global';

const RequireAuth = (props) => {
	const {auth, setAuth} = useContext(AuthContext)
	
	//if(auth == null) {
		axiosDefault.get(Global.apiHost + '/api/auth/check')
	  	.then(res => {
			if(res.data.res == 'success') {
				if(auth == null)
					setAuth(res.data.data)
			} else {
				setAuth(null)
				window.location = Global.apiHost + '/login';				
			}			
		  })
		//return false
	//}
	
	return (
		<>
			{props.children}
		</>
	);
};

export default RequireAuth;
