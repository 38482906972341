import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Import from './Import';

const RadioImport = () => {
	return (
		<>
			<>
				<Import type="radio" />
			</>
		</>
	);
};

export default RadioImport;
