import React, {useEffect} from 'react';
import 'react-select2-wrapper/css/select2.css';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';
import {useParams, useHistory} from 'react-router-dom';
import Global from '../../../Global';
import axios from '../../Helpers/axiosDefault';
require('react-select2');
const $ = require('jquery');

const CampaignsDropdown = () => {

  const params = useParams();
  const history = useHistory();

  const fetchData = () => {
    axios.get(Global.apiHost + '/api/form/select2/campaigns?client_id=' + params.client_id )
    .then(
        (result) => {            
            $('#campaigns-droplist').select2({
                data: result.data.results
            });
            if(!params.campaign_id) {
              history.push('/commercials/' + params.client_id + '/' + $('#campaigns-droplist > option:eq(0)').attr('value'));
            } else {
              $('#campaigns-droplist').val(params.campaign_id).trigger('change');
            }
                                
            $('#campaigns-droplist').on('change', function() {
                history.push('/commercials/' + params.client_id + '/' + $(this).val());
            });
        }
    )
  }

  useEffect(() => {
    if(params.campaign_id && $('#campaigns-droplist').val() == params.campaign_id && $('#campaigns-droplist').val() != null) return;
    if($('#campaigns-droplist').hasClass('select2-hidden-accessible')) {
      $('#campaigns-droplist').off('change');
      $('#campaigns-droplist').select2('destroy');
      $('#campaigns-droplist').html('');
    }
    fetchData();    
  })

  return (
    <select style={{width: "100%"}} id="campaigns-droplist"></select>
  )
}

CampaignsDropdown.propTypes = {};
CampaignsDropdown.defaultProps = {};

export default CampaignsDropdown;
