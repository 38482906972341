import React, { useState, useEffect } from 'react';
import {Form, Button, Row, Col} from 'react-bootstrap';
import {useParams, useHistory} from 'react-router-dom';
import CommercialSize from './CommercialSize';
import SubmitButton from '../../UI/Form/SubmitButton';
import MediaTypeSelect from '../../UI/Form/MediaTypeSelect';
import axios from '../../Helpers/axiosDefault';
import Validate from '../../UI/Form/Validate/Validate';
import Global from '../../../Global';

const CommercialForm = () => {
	const params = useParams()
	const history = useHistory()
	let [category, setCategory] = useState();
	let [advertisement, setAdvertisement] = useState('')
	let [length, setLength] = useState('')
	let [language, setLanguage] = useState('')
	let [commercialId, setCommercialId] = useState('')
	let [isci, setISCI] = useState('')
	let [isTypeDisabled, setDisabled] = useState('')

	let type = {
		'Television': 'VideoCommercialAdvertisement', 
		'Cable': 'VideoCommercialAdvertisement',
		'Radio': 'AudioCommercialAdvertisement',
		'Newspaper': 'PrintAdvertisement',
		'Magazine': 'PrintAdvertisement',
		'OOH': 'OutdoorAdvertisement'
	};

	let sizeLabel = {
		'VideoCommercialAdvertisement':'Duration',
		'AudioCommercialAdvertisement':'Duration',
		'PrintAdvertisement':'Size',
		'OutdoorAdvertisement':'Size'
	}

	const SubmitAction = (e) => {
		e.preventDefault()
		if(!Validate.checkForm()) return false

        let formData = new FormData(document.querySelector('#commercial-form'))
		axios.post(Global['apiHost'] + '/api/commercial/save', formData)
			.then(response => {
				history.push('/commercials/' + params.client_id + '/' + params.campaign_id)
			})		
		return false
    }

	const onChangeMediaType = (e) => {
		setCategory(e.target.value)
	}

	useEffect(() => {
		if(params.commercial_id) {		
			let requestFor = 'duplicate'
			if(params.event == 'edit') requestFor = 'get'

			axios.get(Global['apiHost'] + '/api/commercial/'+requestFor+'/' + params.commercial_id)
				.then(response => {
					if(params.event == 'edit')
						setDisabled('disabled')

					setAdvertisement(response.data.advertisement)
					setLength(response.data.length)
					setLanguage(response.data.language)
					setCategory(response.data.type)
					setCommercialId(response.data.commercial_fm_id)
					setISCI(response.data.isci_ad_id)					
				})			
		} 
		
		if(params.event == 'add' && !params.commercial_id) {
			axios.get(Global.apiHost + '/api/form/commercial/uniqid')
				.then(res => {					
					setCommercialId(res.data)
				})
		}
	}, [params.commercial_id])
	
	return (		
		<Form onSubmit={SubmitAction} style={{width: "60%"}} id="commercial-form">
			<Form.Control type="hidden" name="campaign_id" value={params.campaign_id} />
			{/* <Form.Control type="hidden" id="type" name="type" value={category} /> */}
			{params.commercial_id && params.event == 'edit'?
				<Form.Control type="hidden" id="id" name="id" value={params.commercial_id} />
			:''}
			<Row >
				<Col>                
					<Form.Group controlId="type">
						<Form.Label>Select Commercial Category</Form.Label>
						<MediaTypeSelect disabled={isTypeDisabled} name="type" onChange={onChangeMediaType} value={category} className="required" />
					</Form.Group>
				</Col>
			</Row>
			<br />
			<Row >
				<Col>                
					<Form.Group controlId="advertisement">
						<Form.Label>Commercial Name</Form.Label>
						<Form.Control type="text" name="advertisement" id="advertisement" placeholder="Commercial Name" className="required" defaultValue={advertisement} />
					</Form.Group>
				</Col>   
				<Col xs={3}>                
					<Form.Group controlId="commercial_fm_id">
						<Form.Label>Commercial ID</Form.Label>
						<Form.Control readOnly type="text" name="commercial_fm_id" id="commercial_fm_id" placeholder="Commercial ID" className="required" defaultValue={commercialId} />
					</Form.Group>
				</Col> 
			</Row>
			<br />
			{category?
			<Row >
				<Col>                
					<Form.Group controlId="language">
						<Form.Label>Language</Form.Label>
						<Form.Control as="select" name="language" defaultValue={language}>
							<option value="">--Select Language--</option>
							<option value="English">English</option>
							<option value="Spanish">Spanish</option>
						</Form.Control>
					</Form.Group>
				</Col>   
				{category == 'VideoCommercialAdvertisement'?
					<Col>                
						<Form.Group controlId="isci_ad_id">
							<Form.Label>ISCI Code</Form.Label>
							<Form.Control type="text" name="isci_ad_id" className="required" defaultValue={isci} placeholder="ISCI / Ad ID" />
						</Form.Group>
					</Col>  
				:''}		
				
					<Col>                
						<Form.Group controlId="length">
							<CommercialSize category={category} selected={length} label={sizeLabel[category]} className="required" />						
						</Form.Group>
					</Col> 				
						 
				
			</Row>
			:''}
			<br />			
			<SubmitButton />
		</Form>
	);
};

export default CommercialForm;
