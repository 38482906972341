import React, { useState, useEffect } from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import axios from '../../Helpers/axiosDefault';
import Global from '../../../Global';

const TestTools = (props) => {
	const history = useHistory()	
	const [activeRequest, setActiveRequest] = useState(false)
	const controller = new AbortController()

	const resetDbHandler = () => {
		//controller.abort()
		if(activeRequest) {
			controller.abort()
		}
		if(confirm('Are you sure you want to reset the database?')) {
			document.querySelector('#reset-button').innerHTML = 'Deleting..'
			setActiveRequest(true)
			axios.get(Global.apiHost + '/api/test/resetdb', {
				signal: controller.signal
			})
			.then(res => {
				setActiveRequest(false)
				document.querySelector('#reset-button').innerHTML = 'Reset Database'
				alert(res.data)				
			}).catch(
				function(error) {

				}
			)
		}		
	}

	return (
        <>                          
			<Button variant="primary" className="btn-sm btn-success" id="download_results" type="button" onClick={props.downloadHandler}>Download results</Button> &nbsp;&nbsp;       
            <Button variant="primary" className="btn-sm btn-success" id="reset-button" type="button" onClick={resetDbHandler}>Reset Database</Button>            
        </>
    )    
};

export default TestTools;
