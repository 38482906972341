import React from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Button} from 'react-bootstrap';

const CampaignTools = (props) => {

    const history = useHistory();
    const params = useParams();

    const addCampaign = () => {
        if(!params.client_id) {
            history.push('/clients');
        }
        history.push('/new-campaign/' + params.client_id);
    }

    const editCampaign = () => {
        if(!params.client_id) {
            history.push('/clients');
        }
        history.push('/campaigns/' + params.client_id + '/edit/' + params.campaign_id)
    }

    return (
        <>                        
            {props.showEdits?
                <> 
                    <Button variant="primary" className="btn-sm mr-1" type="button" onClick={editCampaign}>Edit Campaign</Button>
                    {/* <Button variant="primary" className="btn-sm mr-1 btn-danger" type="button">Delete Campaign</Button> */ }
                    <Button variant="primary" className="btn-sm mr-1 btn-info" type="button" onClick={() => history.push('/campaigns/' + params.client_id)}>Show All Campaigns</Button>
                </>
            :''}            
            <Button variant="primary" className="btn-sm btn-success" type="button" onClick={addCampaign}>Add New Campaign</Button>            
        </>
    )    
}

export default CampaignTools;