import React, { useState, useEffect } from 'react';
import {Form, Button, Row, Col} from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const FilterForm = (props) => {

	return (
		<Form style={{width: "60%"}} id="markets-filter" onSubmit={props.onSubmit}>
			<Row >
				<Col>                
					<Form.Group controlId="name">
						<Form.Label>Search Market</Form.Label>
						<Form.Control type="text" name="name" placeholder="" />
					</Form.Group>
				</Col>   
				<Col>                
					<Form.Group controlId="name">
						<Form.Label>Media type</Form.Label>
						<Form.Control name="media_type" as="select">
							<option value="">-- Select Media type --</option>
							<option value="TV">Television</option>
							<option value="Cable">Cable</option>
							<option value="Radio">Radio</option>
							<option value="Newspaper">Newspaper</option>
							<option value="Magazine">Magazine</option>
							<option value="OOH">OOH</option>
						</Form.Control>
					</Form.Group>
				</Col>   
			</Row>
			<br />
			<Row >
				<Col>                
					<Form.Group controlId="description">
						<Button type="submit" variant="primary">Submit</Button>
					</Form.Group>
				</Col>     
			</Row>				
		</Form>
	);
};

export default FilterForm;
