import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../components/UI/Breadcrumb/Breadcrumb';
import Subheader from '../components/UI/Subheader/UI/Subheader';
import Panel from '../components/UI/Panel/UI/Panel';
import Layout from '../components/Layout/Master/Master';
import { Form } from 'react-bootstrap';

const Settings = () => {	
	return (
        <Layout>
			<Breadcrumb name="Settings" />
			<Subheader title="Settings" />
			<Panel title="Settings">
                <Form id="settings-form" onSubmit={SubmitAction} style={{width: "60%"}}>
                    
                </Form>

                <div>
                    this is settings page.
                </div>
			</Panel>			
		</Layout>
	);
};

export default Settings;
