import React, { useState, useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import {Form, Button, Row, Col} from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import SelectBox from '../../UI/Form/SelectBox';
import Global from '../../../Global';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

const FilterForm = (props) => {

	let [client, setClient] = useState('')
	let [campaign, setCampaign] = useState('')
	let [mediaType, setMediaType] = useState('')

	let [periodType, setPeriodType] = useState('date_range')
	let [dateFilter, enableDateFilter] = useState(false)

	const onChangeHandler = (e) => {
		setPeriodType(e.target.value)
	}

	const mediaLoadOptions = (input, callBack) => {
		if(input == undefined) input = ''
		let url = Global.apiHost + '/api/form/media?k=' + input;
		axios.get(url)
			.then(res => {
				callBack(res.data);
			})
	}

	const toggleDateFilter = (e) => {
		if(e.target.checked) enableDateFilter(true)
		else enableDateFilter(false)
	}

	return (
		<Form onSubmit={props.onSubmit} id="results-filter">
			<Row>				
				<Col xs={2}>                
					<Form.Group>
						<Form.Label>Select Input Type</Form.Label>
						<Form.Control name="period_type" id="period_type" as="select" onChange={onChangeHandler} defaultValue={'date_range'}>
							<option value="">-- Select Option --</option>
							<option value="date_range">Date Range</option>
							<option value="week_id">Week Id</option>
						</Form.Control>
					</Form.Group>
				</Col>		
				{periodType == 'date_range'?
				<Col xs={3}> 
					<Form.Group>						
						<Form.Label>Date Range</Form.Label>
						<DateRangePicker>
							<input type="text" name="date_range" className="form-control" />
						</DateRangePicker>
					</Form.Group>
				</Col> 	
				:
				<Col xs={2}> 
					<Form.Group controlId="week_id">
						<Form.Label>WeekID</Form.Label>
						<Form.Control type="text" name="week_id" placeholder="" />
					</Form.Group>
				</Col>	
				}	
				<Col id="media-select-wrap" xs={3}>
					<Form.Label>Search & Select Media</Form.Label>					
						<AsyncSelect className="async-select required" name="media_id" crossOrigin={true} loadOptions={mediaLoadOptions} defaultOptions={true} 
							cacheOptions={false} option={[]} isClearable={true} />					
				</Col>	
				<Col id="media-select-wrap-live" xs={3}>
					<Form.Label>Search & Select Media for Live DB</Form.Label>					
						<AsyncSelect className="async-select required" name="media_id_live" crossOrigin={true} loadOptions={mediaLoadOptions} defaultOptions={true} 
							cacheOptions={false} option={[]} isClearable={true} />					
				</Col>					
				<Col id="inflation_value" xs={3}>
					<br />
					<Form.Group>
						<Form.Label>Change Inflation multiplier</Form.Label>
						<Form.Control name="inflation_multiplier" id="inflation_multiplier" as="select">		
							<option value="1.1">1.1 (Current)</option>
							<option value="1.2">1.2</option>
							<option value="1.3">1.3</option>
							<option value="1.4">1.4</option>
							<option value="1.5">1.5</option>
							<option value="1.6">1.6</option>
							<option value="1.7">1.7</option>
							<option value="1.8">1.8</option>
							<option value="1.9">1.9</option>
							<option value="2.0">2.0</option>
							<option value="2.1">2.1</option>
							<option value="2.2">2.2</option>
							<option value="2.3">2.3</option>
							<option value="2.4">2.4</option>
							<option value="2.5">2.5</option>
							<option value="2.6">2.6</option>
							<option value="2.7">2.7</option>
							<option value="2.8">2.8</option>
							<option value="2.9">2.9</option>
							<option value="3.0">3.0</option>
						</Form.Control>	
					</Form.Group>					
				</Col>	
			</Row>
			<br />
			<Row >
				<Col>                
					<Form.Group controlId="description">
						<Button type="submit" variant="primary">Submit</Button>
					</Form.Group>
				</Col>     
			</Row>	
		</Form>
	);
};

const FilterFormA = withRouter(FilterForm)

export default FilterFormA
