import React from 'react';
import { Form } from 'react-bootstrap';

const MediaTypeSelect = (props) => (
	<Form.Control name={props.name} as="select" onChange={props.onChange} value={props.value} disabled={props.disabled} className={props.className}>
		<option value="">-- Select Category --</option>
		<option value="VideoCommercialAdvertisement">TV / Cable</option>
		<option value="AudioCommercialAdvertisement">Radio</option>
		<option value="PrintAdvertisement">Newspaper / Magazine</option>
		<option value="OutdoorAdvertisement">OOH</option>
	</Form.Control>
);

export default MediaTypeSelect;
