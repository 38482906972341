import React from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import Global from '../../../Global';

const CommercialTools = (props) => {

	const history = useHistory();
    const params = useParams();

	const addCommercial = () => {
		let url = '/commercials/' + params.client_id + '/' + params.campaign_id;
        //alert(url);
        history.push(url + '/add');
    }

	return (
        <>                                   
            <Button variant="primary" className="btn-sm btn-success" type="button" onClick={addCommercial}>Add New Commercial</Button>            
        </>
    )   
};

export default CommercialTools;
