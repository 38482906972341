import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './Sidebar.module.scss';
import Global from  '../../../Global';
import Nav from '../Nav/Nav.js';

const Sidebar = () => (
  <aside className="page-sidebar">
    <div style={{height: "4.125rem", background: "white"}}>                        
        <a href="#" className="page-logo-link page-logo-lg d-flex align-items-center position-relative" data-toggle="modal" data-target="#modal-shortcut">
            <span className=""><img style={{width: "67%", marginTop: "4px", marginLeft: "4px"}} src="/img/logo.jpg" title="Fertile Data 3.0" /></span>
        </a>
        <a href="#" className="page-logo-link page-logo-mini d-flex align-items-center position-relative" data-toggle="modal" data-target="#modal-shortcut">
            <span className=""><img className="width: 100%" src="/img/logo-mini.jpg" title="Fertile Data 3.0" /></span>
        </a>
    </div>
    <Nav />
  </aside>
);

Sidebar.propTypes = {};

Sidebar.defaultProps = {};

export default Sidebar;
